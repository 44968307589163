<template>
  <a :href="anHref" class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline">
     <slot name="contentBefore">
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-chevron-left w-4 h-4"
        >
        <polyline points="15 18 9 12 15 6" />
        </svg>
    </slot>
    <slot name="linkContent">
      <span class="ml-1">
        {{ aText }}
      </span>
    </slot>
  </a>
</template>

<script>
export default {
  name: "LinkComponent",
  props: {
    anHref: {
      type: String,
      default: "/",
    },
    aText: {
      type: String,
      default: "Back",
    },
  },
};
</script>
