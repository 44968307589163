import FormPanel from "../components/forms/SharedComponents/panel.vue";
import nestedModelComponent from "./nestedModelComponent";

export default {
  components: {
    FormPanel,
  },
  mixins: [nestedModelComponent],
  props: {
    disabledPanel: {
      type: Boolean,
      default: false,
    },
    formioData: {
      type: Object,
      default() {
        return {};
      },
    },
    successSubmission: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    disabledPanel: {
      handler: function (val) {
        if (val) this.resetData();
      },
    },
    formioData: {
      handler: function (val) {
        for (const prop in val) {
          if (prop !== "mates") this.value[prop] = val[prop];
        }
      },
      immediate: true,
      deep: true,
    },
    value: {
      handler: function () {
        if (this.successSubmission) this.$emit("changeSuccessSubmission");
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
};
