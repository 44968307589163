<template>
  <section class="mt-2">
    <h2 class="px-4 text-xl font-semibold sm:px-0" @click="controlSection()">
      <button
        :aria-controls="id"
        :aria-expanded="expanded"
        class="flex items-center focus:outline-none"
      >
        <svg
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-down transition-transform transform"
          :class="{ '-rotate-90': !expanded }"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
        <span class="px-4 text-xl font-semibold sm:px-0 ml-1">
          {{ title }}
        </span>
      </button>
    </h2>
    <div
      :id="id"
      class="pb-6"
      :style="{ display: expanded === true ? 'block' : 'none' }"
    >
      <slot name="content" />
    </div>
  </section>
</template>
<script>
export default {
  name: "PageSection",
  props: {
    title: {
      type: String,
      default: "",
    },
    initiallyExpanded: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: "someid",
      required: true,
    },
  },
  data() {
    return {
      expanded: null,
    };
  },
  created() {
    this.expanded = this.initiallyExpanded;
  },
  methods: {
    controlSection() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
