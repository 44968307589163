<template>
  <div>
    <label for="student-name"
      >I,
      <input
        id="student-name"
        v-model="value.studentName"
        type="text"
        class="form-input min-h-10 ml-4"
        :class="{ 'bg-error-100': v$.value.studentName.$error }"
      />
      confirm that I have completed this form and verified my award amounts and
      disbursement dates with the guidance of my financial aid advisor at my
      home institution.
    </label>
    <div
      v-if="v$.value.studentName.required.$invalid"
      class="error text-error-900"
      :class="{ hidden: !v$.value.studentName.$error }"
    >
      {{ validationMessage["required"] }}
    </div>
    <div
      v-if="v$.value.studentName.maxLength.$invalid"
      class="error text-error-900"
      :class="{ hidden: !v$.value.studentName.$error }"
    >
      {{ validationMessage["maxLength"] }} 255 characters.
    </div>
  </div>
</template>

<script>
import { required, maxLength } from "@vuelidate/validators";
import validationMessages from "../../mixins/validationMessages";
import useVuelidate from "@vuelidate/core";
export default {
  name: "StudentNameInput",
  mixins: [validationMessages],
  props: {
    name: {
      type: Object,
      default: function () {
        return {};
      },
    },
    submittedName: {
      type: String,
      default: "",
    },
  },
  emits: ["update:student-name"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        studentName: "",
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:student-name", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (this.submittedName) {
      this.value.studentName = this.submittedName.trim();
    }
  },
  validations: {
    value: {
      studentName: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
