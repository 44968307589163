<template>
  <div class="text-left mt-8">
    <p class="mb-6">
      Pending payments will show on your account until the transaction is
      processed and the funds are transferred. This could take up to 3 days or
      longer depending on your bank. The remaining balance includes your pending
      payment(s).
    </p>

    <div
      v-for="(paymentItem, index) in pendingPayments"
      :key="paymentItem.name + index"
      class="border-solid rounded-md border border-blue-400 p-4 mb-4"
    >
      <p
        :data-cy="'item-name-label-' + index"
        class="text-gray-650 text-xl font-bold mb-4"
      >
        {{ paymentItem.name }}
      </p>

      <label
        v-for="(item, i) in paymentItem.items"
        :key="item.line_id + i"
        class="flex items-center justify-between text-gray-600 text-base font-semibold pb-3 pl-5"
      >
        <span :data-cy="'pending-item-date-label-' + i">
          {{ formatDate(item.created) }}
        </span>
        <span
          :data-cy="'pending-item-total-label-' + i"
          class="ml-4 text-gray-650"
        >
          ${{ formatNumber(item.amount) }} USD
        </span>
      </label>
      <label
        class="flex items-center justify-between text-gray-650 text-xl font-bold mt-1"
      >
        <span
          >Remaining {{ paymentItem.name }} Balance (as of
          {{ currentDate }})</span
        >
        <span :data-cy="'remaining-balance-label-' + index">
          $
          {{ formatNumber(paymentItem.remainingBalance) }}
          USD
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import { toFixed } from "@/mixins/helpers";
import { isValid, format } from "date-fns";

export default {
  name: "PendingPayments",
  props: {
    pendingPayments: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    currentDate() {
      return format(new Date(), "MM/dd/yyyy");
    },
  },
  methods: {
    formatNumber(number) {
      return Number(toFixed(number / 100)).toLocaleString("en-US", {
        minimumFractionDigits: 2,
      });
    },
    formatDate(date) {
      const parseDate = new Date(date);
      return isValid(parseDate) ? format(parseDate, "MM/dd/yyyy") : "";
    },
  },
};
</script>
