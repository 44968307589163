<template>
  <div class="w-full px-4 pb-4 mx-auto applications max-w-7xl sm:px-8 sm:pb-8">
    <div class="my-4">
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </div>
    <div
      class="px-4 py-6 -mx-4 bg-white border-t border-gray-200 sm:-mx-8 md:mx-auto sm:px-8 md:p-12 md:border md:rounded"
    >
      <h1 class="mb-2 text-xl md:font-semibold md:text-2xl">
        Network of Support
      </h1>
      <p class="text-gray-600">
        Who are the people you are counting on as you embark on this adventure
        with API Abroad? We ask all participants to share with us at least one
        emergency contact, or in the case of our underage participants, a legal
        guardian.
      </p>
      <template v-if="loading">
        <spinner>
          <template #spinnercontent>
            <p class="text-gray-600">
              {{ message }}
            </p>
            <router-link
              :to="{
                name: 'applications/landing',
                params: { applicationId: applicationId },
              }"
            >
              <span
                class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
              >
                <span class="ml-1">
                  Something not working as expected? <br />
                  Click here to return to enrollment page.
                </span>
              </span>
            </router-link>
          </template>
        </spinner>
      </template>
      <div
        class="grid grid-cols-1 gap-6 gap-y-12 mt-6 sm:mt-8 md:grid-cols-2"
      >
        <div class="space-y-12">
          <MySupportForm />
          <EmergencyForm />
        </div>
        <div class="space-y-12">
          <UniversitySupportForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../mixins/forms";
import EmergencyForm from "@/components/NetworkOfSupport/Emergency/EmergencyForm.vue";
import MySupportForm from "@/components/NetworkOfSupport/MySupport/MySupportForm.vue";
import Spinner from "../helpers/Spinner";
import UniversitySupportForm from "@/components/NetworkOfSupport/UniversitySupport/UniversitySupportForm.vue";

export default {
  name: "NetworkOfSupportPage",
  components: {
    EmergencyForm,
    MySupportForm,
    Spinner,
    UniversitySupportForm,
  },
  mixins: [forms],
  props: {
    applicationId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: "",
      submissionId: "",
      loading: false,
      message: "",
    };
  },
  mounted() {
    if (!this.isApplicationOwner(this.applicationId)) {
      //redirect to my applications
      this.$router.push({
        name: "applications",
      });
    }
  },
};
</script>
