<template>
  <div v-if="showBadgeInterestTile" class="flex flex-col">
    <router-link
      :to="{
        name: 'digitalBadges',
        params: { applicationId: applicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
    >
      <CheckMarkIcon :completed="completed" />
      <p
        class="text-sm font-semibold ml-4 ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Badges Interests
      </p>
    </router-link>
  </div>
</template>

<script>
import CheckMarkIcon from "@/components/forms/SharedComponents/CheckMarkIcon.vue";
import formioApi from "@/mixins/formIoApi";
import postFormRules from "@/mixins/postFormRules.js";
import { mapState } from "vuex";
import { eventBus } from "@/app";
import { POST_FORM_RULES } from "@/constants";

export default {
  name: "BadgeInterestCard",
  components: { CheckMarkIcon },
  mixins: [formioApi, postFormRules],
  computed: {
    ...mapState(["program", "currentApplicationSubmissions"]),
    formioURL() {
      return "badges";
    },
    completed() {
      return Object.hasOwn(this.currentApplicationSubmissions, this.formioURL);
    },
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  async mounted() {
    if (this.showBadgeInterestTile) {
      eventBus.$emit("addPreDepartureForm", {
        label: "Badges Interests",
        ruleName: POST_FORM_RULES["Badge Interests"],
        routeName: "digitalBadges",
        submitted: this.completed,
      });
    }
  },
};
</script>
