<template>
  <div v-if="showCard" class="flex flex-col">
    <router-link
      :to="{
        name: 'enrollment/academic-information',
        params: { applicationId: $route.params.applicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
    >
      <CheckMarkIcon :completed="completed" />
      <p
        class="text-sm font-semibold ml-4 ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Academic Information
      </p>
    </router-link>
  </div>
</template>

<script>
import academicInformationRules from "../../mixins/academicInformationRules.js";
import CheckMarkIcon from "../forms/SharedComponents/CheckMarkIcon.vue";
import { eventBus } from "../../app";
import { POST_FORM_RULES } from "../../constants";

export default {
  name: "AcademicInformationCard",
  components: { CheckMarkIcon },
  mixins: [academicInformationRules],
  data() {
    return {
      completed: false,
      showCard: false,
    };
  },
  computed: {
    uncompletedRules() {
      return Object.values(this.validRules).filter((rule) => !rule.submission);
    },
  },
  watch: {
    program: {
      handler: function (val) {
        this.setCardProps(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.setCardProps(this.program);
  },
  methods: {
    setCardProps(program) {
      this.setAllForm(program);
      this.getAllSubmission().then(() => {
        this.showCard = this.validRules.length > 0;
        this.completed = this.uncompletedRules.length === 0;

        if (this.showCard) {
          eventBus.$emit("addPreDepartureForm", {
            label: "Academic Information",
            ruleName: POST_FORM_RULES["Academic Information"],
            routeName: "enrollment/academic-information",
            submitted: this.completed,
          });
        }
      });
    },
  },
};
</script>
