<template>
  <div class="mb-6">
    <div class="mb-6">
      <p>
        <strong>Student Signature:</strong> By signing my name below, I
        acknowledge that I have communicated with my home institution advisor
        about my participation in this program and my credit requirements. I
        agree that I am responsible for any corresponding fees, paperwork and/or
        coursework that may be required as a result.
      </p>
    </div>
    <div class="grid grid-cols-1 gap-6 mt-4 md:grid-cols-2">
      <label for="studentName" class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Name of Student</span>
        </span>
        <input
          id="studentName"
          v-model.trim="v$.value.studentName.$model"
          type="text"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.value.studentName.$error }"
        />
        <div
          v-if="
            v$.value.studentName.required.$invalid &&
            v$.value.studentName.$error
          "
          class="error text-error-900"
        >
          Field is required
        </div>
      </label>
      <Datepicker
        label-name="Date"
        :date="v$.value.date.$model"
        :required="true"
        :min-year="1950"
        @updateDate="updateDate($event)"
      />
    </div>
  </div>
</template>

<script>
import Datepicker from "../SharedComponents/Datepicker.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  name: "StudentSignature",
  components: { Datepicker },
  props: {
    studentSignature: {
      type: Object,
      default() {
        return {};
      },
    },
    formioData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["update:student-signature"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        studentName: "",
        date: "",
      },
    };
  },
  validations: {
    value: {
      studentName: { required },
      date: { required },
    },
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:student-signature", this.value);
      },
      deep: true,
      immediate: true,
    },
    formioData: {
      handler: function (newValue) {
        this.value.studentName = newValue.name;
        this.value.date = newValue.date;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateDate(date) {
      this.v$.value.date.$model = date;
    },
  },
};
</script>
