<template>
  <button :data-tippy-content="tooltip" data-cy="tooltip" type="button">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-help-circle text-muted inline w-3.5"
    >
      <circle cx="12" cy="12" r="10"></circle>
      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
      <line x1="12" y1="17" x2="12.01" y2="17"></line>
    </svg>
  </button>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    tooltip: {
      type: String,
      default: "",
    },
  },
};
</script>
