<template>
  <div v-if="show" class="flex flex-col">
    <router-link
      class="flex flex-row justify-start items-center mb-2"
      :to="{
        name: 'enrollment/participant-agreement',
        params: { applicationId: $route.params.applicationId },
      }"
    >
      <CheckMarkIcon :completed="completed" />
      <p
        class="text-sm font-semibold ml-4 ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Participant Agreement
      </p>
    </router-link>
  </div>
</template>
<script>
import formIoApi from "../../mixins/formIoApi.js";
import CheckMarkIcon from "../forms/SharedComponents/CheckMarkIcon.vue";
import { eventBus } from "../../app";
import { POST_FORM_RULES } from "../../constants";
import { mapState } from "vuex";

export default {
  name: "ParticipantAgreementCard",
  components: {
    CheckMarkIcon,
  },
  mixins: [formIoApi],
  props: {
    programDetails: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      completed: false,
      show: false,
      rules: {
        virtual: {
          rule: "Virtual Participant Agreement",
          url: "participantagreementvirtual",
          exist: false,
          completed: false,
        },
        study: {
          rule: "Study and Intern Participant Agreement",
          url: "participantagreementstudyintern",
          exist: false,
          completed: false,
        },
      },
    };
  },
  computed: mapState(["currentApplicationSubmissions"]),
  watch: {
    programDetails: {
      handler: function (val) {
        this.showParticipantAgreement(val);
        this.cardCompleted();
      },
      deep: true,
    },
  },
  mounted() {
    this.showParticipantAgreement(this.programDetails);
    this.cardCompleted();
  },
  methods: {
    cardCompleted() {
      if (this.show) {
        this.getAllSubmission().then(() => {
          this.completed = Object.values(this.rules).some(
            (rule) => rule.exist && rule.completed
          );

          eventBus.$emit("addPreDepartureForm", {
            label: "Participant Agreement",
            ruleName: POST_FORM_RULES["Participant Agreement"],
            routeName: "enrollment/participant-agreement",
            submitted: this.completed,
          });
        });
      }
    },
    showParticipantAgreement(programDetails) {
      let hasFormRules = Object.hasOwnProperty.call(
        programDetails,
        "post_acceptance_form_rules"
      );
      let rules = hasFormRules ? programDetails.post_acceptance_form_rules : [];

      Object.entries(this.rules).forEach(([key, val]) => {
        this.rules[key].exist = rules.includes(val.rule);
      });

      let hasAgreement = Object.values(this.rules).some((rule) => rule.exist);
      this.show = hasFormRules && hasAgreement;
    },

    async getAllSubmission() {
      for (const [key, val] of Object.entries(this.rules)) {
        if (val.exist) {
          this.rules[key].completed = Object.hasOwn(
            this.currentApplicationSubmissions,
            val.url
          );
        }
      }
    },
  },
};
</script>
