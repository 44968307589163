import { mapState } from "vuex";

export default {
  data() {
    return {
      rules: {
        inter: {
          rule: "Birth Certificate Copy",
          url: "birthcertificatecopy",
          exist: false,
          submission: "",
        },
      },
    };
  },
  computed: {
    ...mapState([
      "program",
      "formioToken",
      "studentApplications",
      "currentApplicationSubmissions",
    ]),
    enrollmentApplicationId() {
      return this.$route.params.applicationId;
    },
    validRules() {
      return Object.values(this.rules).filter((rule) => rule.exist);
    },
  },
  methods: {
    setAllForm(program) {
      const formRules = program.post_acceptance_form_rules;

      if (formRules && formRules.length) {
        Object.entries(this.rules).forEach(([key, val]) => {
          this.rules[key].exist = formRules.includes(val.rule);
        });
      } else {
        Object.assign(this.$data, this.$options.data());
      }
    },
    async getAllSubmission() {
      for (const [key, val] of Object.entries(this.rules)) {
        if (val.exist) {
          this.rules[key].submission =
            (this.currentApplicationSubmissions[val.url] ?? [])[0] ?? "";
        }
      }
    },
  },
};
