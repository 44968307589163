<template>
  <div
    id="upload-complete-container"
    data-cy="upload-complete-container"
    class="text-center text-gray-650"
  >
    <h2 class="text-xl md:text-3xl">Upload Complete</h2>

    <img :src="'/images/' + icon" alt="Complete icon" class="mx-auto my-10" />

    <p class="text-lg">{{ added }} students successfully processed.</p>
    <p v-if="failed" class="text-lg">
      {{ failed }} students could not be added. Please try again.
    </p>

    <div class="align-center space-y-5 mt-10">
      <ButtonWithSpinner
        ref="closeBtn"
        data-cy="close-btn"
        type="button"
        variant="secondary-outlined"
        variant-type="normal"
        @click="$emit('cancel-btn')"
      >
        <span>Close</span>
      </ButtonWithSpinner>

      <ButtonWithSpinner
        v-if="failed"
        ref="tryAgainBtn"
        data-cy="try-again-btn"
        type="button"
        variant="secondary"
        variant-type="normal"
        @click="$emit('upload-again-btn')"
      >
        <span>Try Again</span>
      </ButtonWithSpinner>
    </div>
  </div>
</template>

<script>
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";

export default {
  name: "UploadComplete",
  components: {
    ButtonWithSpinner,
  },
  props: {
    processResult: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["cancel-btn", "upload-again-btn"],
  computed: {
    added() {
      return this.processResult.succeeded ?? 0;
    },
    failed() {
      return this.processResult.failed ?? 0;
    },
    icon() {
      return this.failed ? "icon-warning-60.svg" : "icon-completed-60.svg";
    },
  },
};
</script>
