<template>
  <div>
    <label class="block mt-2">
      <span class="text-gray-600 flex justify-between mb-2">
        <span :id="inputName + '-label'">{{ label }}</span>
      </span>
      <input
        v-model="inputValue"
        type="text"
        :placeholder="placeHolder"
        class="form-input block w-full min-h-10"
        :name="inputName"
        :class="{
          'bg-error-100': validationErrors.$error,
        }"
        :aria-labelledby="inputName + '-label'"
      />
    </label>
    <span v-for="(value, rule) in sortedValidationRules" :key="rule">
      <span
        v-if="validationErrors[rule].$invalid"
        :data-cy="name + '-validation'"
        class="error text-error-900"
        :class="{ hidden: !validationErrors.$error }"
      >
        <p v-if="rule === 'maxLength'">
          {{ validationMessage[rule] + " " + maxLength }}
        </p>
        <p v-else>{{ validationMessage[rule] }}</p>
      </span>
    </span>
  </div>
</template>

<script>
import validationMessages from "../../../mixins/validationMessages";
export default {
  mixins: [validationMessages],
  props: {
    name: {
      type: String,
      default: "",
    },
    maxLength: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    ariaLabel: {
      type: String,
      default: "",
    },
    valueFromDatabase: {
      type: String,
      default: "",
    },
    placeHolder: {
      type: String,
      default: "",
    },
  },
  emits: ["input"],
  data() {
    return {
      inputValue: "",
      inputName: "",
    };
  },
  computed: {
    generateId() {
      if (this.name) {
        return this.name;
      }
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 10; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },
  watch: {
    inputValue: function (newVal) {
      this.$emit("input", newVal);
    },
  },
  created() {
    this.inputName = this.generateId;
    if (this.valueFromDatabase) {
      this.inputValue = this.valueFromDatabase.trim();
    }
  },
};
</script>
