// import the tippy
import { delegate } from "tippy.js";

delegate("body", {
  target: ".has-tippy",
  theme: "light",
  content: "Loading...",
  allowHTML: true,
  interactive: true,
  aria: {
    content: "describedby",
  },
  role: "tooltip",
  onShow(instance) {
    // Code here is executed every time the tippy shows
    let id = instance.reference.dataset.tippyTemplate;
    let template = document.getElementById(id);
    instance.setContent(template.innerHTML);
  },
});

//Event delegation for tooltips that use the data attribute
delegate("body", {
  target: "[data-tippy-content]",
  theme: "light",
  interactive: true,
  aria: {
    content: "describedby",
  },
  role: "tooltip",
});
