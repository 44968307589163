<template>
  <fieldset>
    <legend>
      Do you have any mobility limitations?
    </legend>
    <RadioButton
      name="MobilityRadio"
      aria-controls="mobility-radio"
      :options="['yes', 'no']"
      :selected-option="selectedOption"
      :validation-errors="v$.value.selectedRadio"
      @input="onFieldChange('selectedRadio', $event)"
    />

    <div
      v-if="value.selectedRadio === 'yes'"
      id="mobility-radio"
      :aria-expanded="value.selectedRadio === 'yes'"
    >
      <div class="grid grid-cols-1 gap-y-8 ml-6">
        <ApiTextArea
          name="mobility"
          label="If yes, please describe your condition and any needed accommodations."
          :max-length="500"
          :typed-text="typedText"
          :validation-errors="v$.value.textarea"
          @textUpdate="onFieldChange('textarea', $event)"
        />
        <p class="italic">
          Note: Participants with limited mobility are advised that API cannot
          guarantee that host universities, host organizations or housing
          accommodations abroad will be ADA compliant.
        </p>
      </div>
    </div>
  </fieldset>
</template>

<script>
import formInputs from "../../mixins/formInputs";
import RadioButton from "../forms/SharedComponents/RadioButton";
import ApiTextArea from "../forms/SharedComponents/ApiTextArea";
import { required, requiredIf, maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  name: "Mobility",
  components: { RadioButton, ApiTextArea },
  mixins: [formInputs],
  props: {
    mobility: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:mobility"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        selectedRadio: "",
        textarea: "",
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:mobility", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {
    value: {
      selectedRadio: {
        required,
      },
      textarea: {
        required: requiredIf(function () {
          return this.value.selectedRadio === "yes";
        }),
        maxLength: maxLength(500),
      },
    },
  },
};
</script>
