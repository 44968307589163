<template>
  <div v-if="showCpCodeOfConductTile" class="flex flex-col">
    <router-link
      :to="{
        name: 'cpcodeofconduct',
        params: { applicationId: applicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
    >
      <CheckMarkIcon :completed="completed" />
      <p
        class="text-sm font-semibold ml-4 ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Customized Code Of Conduct
      </p>
    </router-link>
  </div>
</template>

<script>
import CheckMarkIcon from "@/components/forms/SharedComponents/CheckMarkIcon.vue";
import postFormRules from "@/mixins/postFormRules";
import { eventBus } from "@/app";
import { POST_FORM_RULES } from "@/constants";
import { mapState} from "vuex";

export default {
  name: "CPCodeOfConductCard",
  components: {
    CheckMarkIcon,
  },
  mixins: [postFormRules],
  props: {
    applicationId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formPath: "cpcodeofconduct",
    };
  },
  computed: {
    ...mapState(["currentApplicationSubmissions"]),
    completed() {
      return Object.hasOwn(this.currentApplicationSubmissions, this.formPath);
    },
  },
  async mounted() {
    if (this.showCpCodeOfConductTile) {
      eventBus.$emit("addPreDepartureForm", {
        label: "Customized Code Of Conduct",
        ruleName: POST_FORM_RULES["Customized Code Of Conduct"],
        routeName: "cpcodeofconduct",
        submitted: this.completed,
      });
    }
  },
};
</script>
