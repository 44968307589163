<template>
  <FormPanel
    :id="'orderOfImportance'"
    :title="'Order of Importance'"
    :disabled-panel="disabledPanel"
    :initially-expanded="!disabledPanel"
    class="mt-6"
    panel-content-class="text-gray-600 border-gray-200"
  >
    <template #content>
      <p class="mb-6">
        Please rank each of the following items in order of importance to you,
        with 1 being the most important and 3 being the least. We will do our
        best to accomodate all of your requests, but in some cases this may not
        be possible.
      </p>
      <div class="grid grid-cols-1 gap-y-4">
        <div
          class="flex text-xs font-semibold tracking-widest text-gray-500 uppercase"
        >
          <div class="w-16 mr-6">Rank</div>
          <div>Area of Importance</div>
        </div>
        <label
          v-for="option in ORDER_OPTIONS"
          :key="option.model"
          class="flex items-center"
          :for="option.model"
        >
          <div class="w-16 mr-6">
            <select
              :id="option.model"
              v-model="v$.value[option.model].$model"
              class="form-select w-full"
            >
              <option
                v-for="n in 3"
                :key="'option' + n"
                :value="{ label: n.toString(), value: 'option' + n }"
              >
                {{ n }}
              </option>
            </select>
          </div>
          <div class="text-sm md:text-base">
            {{ option.label }}
          </div>
        </label>
        <div
          v-if="v$.value.validateDuplicates.$invalid"
          class="error text-error-900 text-sm"
        >
          Rank can not be duplicate
        </div>
      </div>
    </template>
  </FormPanel>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import housingForm from "../../../mixins/housingForm.js";

const ORDER_OPTIONS = [
  {
    label: "Accommodation Style (i.e. apartment)",
    model: "accommodationStyle",
  },
  {
    label: "Single Room",
    model: "singleRoom",
  },
  {
    label: "Roommate Request",
    model: "roommateRequest",
  },
];
const validateDuplicates = (value, vm) => {
  let values = Object.values(vm.value)
    .map((item) => {
      return item.value;
    })
    .filter((item) => item);

  let isDuplicate = values.some((item, index) => {
    return values.indexOf(item) != index;
  });
  return !isDuplicate;
};

export default {
  name: "OrderOfImportance",
  mixins: [housingForm],
  props: {
    orderOfImportance: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:order-of-importance"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        accommodationStyle: {},
        singleRoom: {},
        roommateRequest: {},
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        if (this.successSubmission) this.$emit("changeSuccessSubmission");
        this.$emit("update:order-of-importance", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {
    value: {
      accommodationStyle: {},
      singleRoom: {},
      roommateRequest: {},
      validateDuplicates,
    },
  },
  created() {
    this.ORDER_OPTIONS = ORDER_OPTIONS;
  },
};
</script>
