<template>
  <div>
    <BulkEnrollmentParentContainer
      :open="isBulkEnrollmentModalOpen"
      @toggledisplay="toggleBulkEnrollmentModal"
    />
    <div
      id="students"
      class="w-full mx-auto max-w-7xl md:px-8 md:mb-8 md:justify-around"
    >
      <div class="mx-4 my-6 md:flex md:items-center md:justify-between">
        <h2 class="mr-4 text-xl font-semibold">Student Management</h2>

        <div class="md:flex md:w-100">
          <button
            @click="toggleBulkEnrollmentModal"
            class="bg-teal-900 h-10 text-white font-semibold py-1 px-4 rounded inline-flex items-center md:mx-8 mt-2 md:mt-0"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-plus-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="16"></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
            <span class="ml-2 text-sm font-semibold text-white">
              Student
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ParentModal from "./BulkEnrollment/ParentModal";

export default {
  name: "StudentManagement",
  components: { BulkEnrollmentParentContainer: ParentModal },
  data() {
    return {
      isBulkEnrollmentModalOpen: false,
    };
  },
  computed: {
    ...mapState(["currentUser", "enrollmentToken"]),
  },
  methods: {
    toggleBulkEnrollmentModal() {
      this.isBulkEnrollmentModalOpen = !this.isBulkEnrollmentModalOpen;
    },
  },
};
</script>

<style></style>
