import { mapState } from "vuex";
import { Formio } from "formiojs";
import tailwind from "@apiabroad/formio-tailwind-template";
import impersonationFormSniffer from "./ImpersonationFormSniffer";
import formService from "@/services/form";

export default {
  mixins: [impersonationFormSniffer],
  computed: {
    ...mapState(["studentApplications"]),
    enrollmentApplicationId() {
      return this.$route.params.applicationId;
    },
  },
  methods: {
    async validateApplication() {
      if (!this.studentApplications.length) {
        await this.$store.dispatch("getFormioSubmissions");
        await this.$store.dispatch("getStudentApplications");
      }
      return this.studentApplications.some(
        (app) => app.id === this.enrollmentApplicationId
      );
    },
    createForm(formioURL, div, submissionId, formPath) {
      let formURL = formioURL;
      if (submissionId) {
        formURL += "/submission/" + submissionId;
      }

      Formio.setBaseUrl(`${window.location.protocol}//${window.location.host}`);
      Formio.setAuthUrl(process.env.MIX_FORMIO_ENDPOINT);
      Formio.use(tailwind);
      return Formio.createForm(document.getElementById(div), formURL, {
        sanitizeConfig: {
          addAttr: [
            "focusable",
            "viewBox",
            "fill",
            "stroke",
            "stroke-width",
            "stroke-linecap",
            "stroke-linejoin",
            "cx",
            "cy",
            "r",
            "x1",
            "x2",
            "y1",
            "y2",
            "points",
            "d",
          ],
          addTags: ["svg", "circle", "line", "polyline", "path"],
        },
        hooks: {
          beforeSubmit: (submission, next) => {
            submission.data.application_id = this.enrollmentApplicationId;
            submission = this.attachEmployeeToSubmission(submission, "post");
            next();
          },
        },
        noAlerts: true,
      }).then((form) => {
        // suppress the Form.io SDK default submit behavior so that we can submit
        // to our own backend with all necessary CSRF protection
        form.nosubmit = true;
        form.on("submit", (submission) => {
          formService
            .createOrUpdateSubmission(formPath, submission, submissionId)
            .then((submission) => {
              this.logSubmission(form._form.path, {}, submission.data);
              form.emit("submitDone", submission);
            });
        });
      });
    },
  },
};
