<template>
  <div v-if="showCard" class="flex flex-col">
    <router-link
      :to="{
        name: 'polimoda-student-regulation-policy',
        params: { applicationId: enrollmentApplicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
    >
      <CheckMarkIcon :completed="completed" />
      <p
        class="text-sm font-semibold ml-4 ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Polimoda Student Privacy Policy
      </p>
    </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
import formIoApi from "../../mixins/formIoApi.js";
import CheckMarkIcon from "../forms/SharedComponents/CheckMarkIcon.vue";
import { eventBus } from "../../app";

export default {
  name: "PolimodaPrivacyPolicyCard",
  components: { CheckMarkIcon },
  mixins: [formIoApi],
  data() {
    return {
      showCard: false,
      formURL: "polimodastudentprivacypolicy",
    };
  },
  computed: {
    ...mapState(["program", "currentApplicationSubmissions"]),
    completed() {
      return Object.hasOwn(this.currentApplicationSubmissions, this.formURL);
    },
    enrollmentApplicationId() {
      return this.$route.params.applicationId;
    },
  },
  watch: {
    program: {
      handler: function (val) {
        this.setCardProps(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.setCardProps(this.program);
  },
  methods: {
    setCardProps(program) {
      const formRules = program.post_acceptance_form_rules;
      this.showCard = false;

      if (formRules && formRules.length) {
        this.showCard = formRules.includes(
          "Polimoda Student Regulations Policy"
        );
        this.isCompleted();
      }
    },
    isCompleted() {
      if (this.showCard) {
        eventBus.$emit("addPreDepartureForm", {
          label: "Polimoda Student Privacy Policy",
          ruleName: "Polimoda Student Regulations Policy",
          routeName: "polimoda-student-regulation-policy",
          submitted: this.completed,
        });
      }
    },
  },
};
</script>
