<template>
  <PageStyle>
    <template #pageHeader>
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </template>
    <template #pageContent>
      <h1 class="mb-2 text-xl md:font-semibold md:text-2xl">
        Code of Conduct High School Programs
      </h1>
      <div
        id="hs-code-of-conduct"
        role="form"
        aria-label="Code of Conduct High School Programs Form"
        class="mt-10 relative"
      />
    </template>
  </PageStyle>
</template>

<script>
import { Formio } from "formiojs";
import tailwind from "@apiabroad/formio-tailwind-template";
import PageStyle from "@/components/forms/SharedComponents/Layout/PageDefault.vue";
import formIoApi from "@/mixins/formIoApi.js";
import forms from "@/mixins/forms.js";
import formService from "../../../services/form";

export default {
  name: "HSCodeOfConductForm",
  components: {
    PageStyle,
  },
  mixins: [formIoApi, forms],
  data() {
    return {
      submissionId: "",
      formURL: "hscodeofconduct",
    };
  },
  computed: {
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  async created() {
    const valid = await this.isValidApplication(
      this.applicationId,
      "HS Code of Conduct",
      "post_acceptance_form_rules"
    );

    if (valid) {
      this.getFormioData();
    } else {
      window.location.href = "/applications";
    }
  },
  methods: {
    getFormioData() {
      this.formioSubmissionExists(this.formURL, this.applicationId).then(
        (submissionId) => {
          this.submissionId = submissionId;
          this.createPDF();
        }
      );
    },
    createPDF() {
      let element = document.getElementById("hs-code-of-conduct");
      let url = "/api/forms/" + this.formURL;
      if (this.submissionId) {
        url += "/submission/" + this.submissionId;
      }
      Formio.setBaseUrl(`${window.location.protocol}//${window.location.host}`);
      Formio.setAuthUrl(
        `${window.location.protocol}//${window.location.host}/api/forms`
      );
      Formio.use(tailwind);
      Formio.createForm(element, url, {
        hooks: {
          beforeSubmit: (submission, next) => {
            submission.data.application_id = this.applicationId;

            if (!submission.data.studentSignature) {
              let signatureErrorDiv = document.getElementById(
                "student-signature-error"
              );

              if (!signatureErrorDiv) {
                let errorDivs = document.querySelectorAll(
                  ".apiabroad-error-message"
                );
                let element = document.createElement("p");
                element.setAttribute("id", "student-signature-error");
                element.textContent =
                  "Student signature: Student signature is required";
                errorDivs[errorDivs.length - 1].append(element);
              }
            }

            next();
          },
        },
        noAlerts: true,
      }).then((form) => {
        // suppress the Form.io SDK default submit behavior so that we can submit
        // to our own backend with all necessary CSRF protection
        form.nosubmit = true;
        form.on("submit", (submission) => {
          formService
            .createOrUpdateSubmission(
              this.formURL,
              submission,
              this.submissionId
            )
            .then((submission) => {
              form.emit("submitDone", submission);
            });
        });
        form.on("change", (event) => {
          if (
            event.changed &&
            event.changed.component.key === "studentSignature"
          ) {
            if (event.changed.value) {
              let signatureErrorDiv = document.getElementById(
                "student-signature-error"
              );
              if (signatureErrorDiv) signatureErrorDiv.remove();
            }
          }
        });
        form.on("submitDone", () => {
          this.$router.push({
            name: "applications/landing",
            params: {
              applicationId: this.applicationId,
            },
          });
        });
      });
    },
  },
};
</script>
<style>
.has-error > .help-block {
  display: none;
}
</style>
