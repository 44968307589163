<template>
  <div
    class="flex md:col-start-5 lg:col-start-6 mt-1 text-sm text-gray-600 md:col-span-4 lg:col-span-3 md:text-base md:mt-0"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-check-circle w-5 h-5 text-success-800"
    >
      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
      <polyline points="22 4 12 14.01 9 11.01"></polyline>
    </svg>

    <div class="text-gray-600 leading-tight">
      Completed
    </div>
  </div>
</template>
