<template>
  <div v-if="showCard" class="flex flex-col">
    <router-link
      :to="{
        name: 'enrollment/parsons-paris-agreement',
        params: { applicationId: enrollmentApplicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
    >
      <CheckMarkIcon :completed="completed" />
      <p
        class="text-sm font-semibold ml-4 ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Parsons Paris Use of Your Image and Your Work
      </p>
    </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
import formIoApi from "../../mixins/formIoApi.js";
import CheckMarkIcon from "../forms/SharedComponents/CheckMarkIcon.vue";
import { eventBus } from "../../app";

export default {
  name: "ParsonsParisAgreementCard",
  components: {
    CheckMarkIcon,
  },
  mixins: [formIoApi],
  data() {
    return {
      showCard: false,
      formURL: "parsonsparisagreement",
    };
  },
  computed: {
    ...mapState(["program", "currentApplicationSubmissions"]),
    enrollmentApplicationId() {
      return this.$route.params.applicationId;
    },
    completed() {
      return Object.hasOwn(this.currentApplicationSubmissions, this.formURL);
    },
  },
  watch: {
    program: {
      handler: function (val) {
        this.setCardProps(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.setCardProps(this.program);
    if (this.showCard) {
      eventBus.$emit("addPreDepartureForm", {
        label: "Parsons Paris Use of Your Image and Your Work",
        ruleName: "Parsons Paris Use Agreement",
        routeName: "enrollment/parsons-paris-agreement",
        submitted: this.completed,
      });
    }
  },
  methods: {
    setCardProps(program) {
      const formRules = program.post_acceptance_form_rules;
      this.showCard = false;

      if (formRules && formRules.length) {
        this.showCard = formRules.includes("Parsons Paris Use Agreement");
      }
    },
  },
};
</script>
