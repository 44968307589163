<template>
  <PageStyle>
    <template #pageHeader>
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </template>
    <template #pageContent>
      <Online
        v-if="online"
        :application-id="applicationId"
        :formio-u-r-l="formioURL"
        :form-u-r-l="formURL"
        :submission-id="submissionId"
        :formio-data="formioData"
        @updatedSubmission="updatedSubmission"
      />
      <InPerson
        v-if="inperson"
        :application-id="applicationId"
        :formio-u-r-l="formioURL"
        :form-u-r-l="formURL"
        :submission-id="submissionId"
        :formio-data="formioData"
        @updatedSubmission="updatedSubmission"
      />
    </template>
  </PageStyle>
</template>

<script>
import PageStyle from "../SharedComponents/Layout/PageDefault.vue";
import Online from "./Online.vue";
import InPerson from "./InPerson.vue";
import forms from "../../../mixins/forms.js";
import { mapState } from "vuex";
import formIoApi from "../../../mixins/formIoApi.js";

export default {
  name: "VisaAppointmentForm",
  components: {
    PageStyle,
    Online,
    InPerson,
  },
  mixins: [forms, formIoApi],
  data() {
    return {
      online: false,
      inperson: false,
      formURL: "visaappointmentinformationinperson",
      submissionId: "",
      formioData: {},
    };
  },
  computed: {
    ...mapState(["program"]),
    applicationId() {
      return this.$route.params.applicationId;
    },
    formioURL() {
      return "/api/forms/";
    },
  },
  async created() {
    const valid = await this.isValidApplication(this.applicationId, "");
    if (valid) await this.validRules();

    if (!this.online && !this.inperson) {
      window.location.href = "/applications";
    } else {
      this.getFormioData();
    }
  },
  methods: {
    async validRules() {
      if (Object.keys(this.program).length === 0) {
        await this.$store.dispatch("setProgramDataByApplicationId", {
          applicationId: this.applicationId,
          apolloClient: this.$apollo,
        });
      }

      this.online = await this.isFormAllowedByFormRules(
        "Visa Appointment Information (online)",
        this.applicationId,
        "post_acceptance_form_rules"
      );

      this.inperson = await this.isFormAllowedByFormRules(
        "Visa Appointment Information (In Person)",
        this.applicationId,
        "post_acceptance_form_rules"
      );
    },
    getFormioData() {
      this.formioSubmissionExists(this.formURL, this.applicationId).then(
        (submissionId) => {
          this.submissionId = submissionId ? submissionId : "";
          if (this.submissionId) {
            this.getFormioSubmission(this.formURL, this.submissionId).then(
              (response) => {
                this.formioData = response;
              }
            );
          }
        }
      );
    },
    updatedSubmission(newSubmissionId) {
      this.submissionId = newSubmissionId;
    },
  },
};
</script>
