import { mapState } from "vuex";
import FormioUtils from "formiojs/utils";

export default {
  computed: {
    ...mapState(["program"]),
    /**
     * Showing/hiding Travel Documents tile
     * @returns {boolean}
     * */
    showHealthAndWellnessTile() {
      return this.showTile(["Health And Wellness", "CP Health and Wellness"]);
    },
    /**
     * Show housing tile
     * @returns {boolean}
     * */
    showHousingTile() {
      return this.showTile([
        "Accommodation Style Preference",
        "Single Room Preference",
        "Requesting a Roommate",
        "Declining a Roommate",
        "Requesting a Housemate",
        "Ranking Your Housing Preference",
        "Housing Questionnaire",
      ]);
    },
    /**
     * Show Network of Support tile
     * @returns {boolean}
     */
    showNetworkOfSupportTile() {
      return this.showTile(["Network of Support"]);
    },
    /**
     * Show Travel Plans tile
     * @returns {boolean}
     */
    showTravelPlansTile() {
      return this.showTile([
        "CP Departure Guidelines",
        "Arrival Guidelines",
        "Arrival FAQ",
        "Departure Guidelines",
        "Student Travel Arrangements",
        "CP Arrival Guidelines - Group Flight",
        "CP Arrival FAQ",
      ]);
    },
    showCourseSelectionCard() {
      return this.showTile(["Course Selection"]);
    },
    /**
     * Showing/hiding Group Visa tile
     * @returns {boolean}
     * */
    showGroupVisaTile() {
      return this.showTile(["Group Visa Information"]);
    },
    /**
     * Showing/hiding passport tile
     * @returns {boolean}
     * */
    showPassportTile() {
      return this.showTile(["Passport Info"]);
    },
    /**
     * Show Visa Appointment tile
     * @returns {boolean}
     */
    showVisaAppointmentTile() {
      return this.showTile([
        "Visa Appointment Information (online)",
        "Visa Appointment Information (In Person)",
      ]);
    },
    /**
     * Show Visa And Permit tile
     * @returns {boolean}
     */
    showVisaAndPermitTile() {
      return this.showTile(["Visa Upload"]);
    },

    /**
     * Show CP Participant Agreement Form - No Insurance, Direct Pay
     * @returns {boolean}
     */
    showCpAgreementNoInsuranceDirectPayTitle() {
      return this.showTile([
        "CP Participant Agreement Form - No Insurance, Direct Pay",
      ]);
    },

    /**
     * Show Headshot tile
     * @returns {boolean}
     */
    showHeadshotTile() {
      return this.showTile(["Head Shot"]);
    },
    /**
     * Show Badge Interest tile
     * @returns {boolean}
     */
    showBadgeInterestTile() {
      return this.showTile(["Badge Interests"]);
    },
    /**
     * Show Customized Code of conduct tile
     * @returns {boolean}
     */
    showCpCodeOfConductTile() {
      return this.showTile(["CP Code of Conduct- Study"]);
    },
    /**
     * Show Supplemental tile
     * @returns {boolean}
     */
    showSupplementalTile() {
      return this.showTile(["Supplemental Form"]);
    },
    /**
     * Show CP Participant Agreement Form - Insurance, No Direct Pay tile
     * @returns {boolean}
     */
    showCpAgreementTile() {
      return this.showTile([
        "CP Participant Agreement Form - Insurance, No Direct Pay",
      ]);
    },
    /**
     * Show Participant Agreement - Insurance & Direct Pay Tile
     * @returns {boolean}
     */
    showParticipantAgreementIDTile() {
      return this.showTile([
        "CP Participant Agreement Form - Insurance & Direct Pay",
      ]);
    },
    /**
     * Show Code of Conduct High School Programs Tile
     * @returns {boolean}
     */
    showHSCodeOfConductTile() {
      return this.showTile(["HS Code of Conduct"]);
    },
  },
  methods: {
    showTile(rules) {
      // If form rules exist but length is zero, hide form
      if (
        this.program.post_acceptance_form_rules &&
        this.program.post_acceptance_form_rules.length === 0
      ) {
        return false;
      }

      // if form rules exist and has length and they don't include at least one of the rules, hide form
      if (
        this.program.post_acceptance_form_rules &&
        this.program.post_acceptance_form_rules.length &&
        !rules.some((el) =>
          this.program.post_acceptance_form_rules.includes(el)
        )
      ) {
        return false;
      }
      return true;
    },
    /**
     * Remove form.io forms that are not in the post acceptance form form_rules
     */
    processPostAcceptanceRulesFormio(form) {
      if (
        this.program.post_acceptance_form_rules &&
        this.program.post_acceptance_form_rules.length
      ) {
        let componentsToRemove = [];
        FormioUtils.eachComponent(
          form.components,
          (component) => {
            const componentRuleName =
              component.component.properties["data-label"];
            if (componentRuleName) {
              if (
                !this.program.post_acceptance_form_rules.includes(
                  componentRuleName
                )
              ) {
                componentsToRemove.push(component);
              }
            }
          },
          true
        );
        componentsToRemove.forEach((component) => {
          form.removeComponent(component);
        });
        form.redraw();
      }
    },
  },
};
