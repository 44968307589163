export const formConfig = {
  data() {
    return {
      formDesigns: [
        {
          name: "Primary Passport",
          handle: "studentApplicationStage2Step2", // Form.io form ubication
          showSections: [
            "passportInformation",
            "PassportSection",
            "PassportForm",
            "issuing-authority-01",
          ],
          extraParams: [],
        },
        {
          name: "Primary Passport Form",
          handle: "studentApplicationStage2Step2",
          showSections: [
            "passportInformation",
            "PassportSection",
            "PassportForm",
            "issuing-authority-01",
          ],
          extraParams: [],
        },
        {
          name: "Secondary Passport Form",
          handle: "studentApplicationStage2Step2",
          showSections: [
            "PassportSection02",
            "PassportForm",
            "passportSectionColumns",
          ],
          extraParams: [],
        },
        {
          name: "Resume",
          handle: "studentApplicationStage2Step2",
          showSections: ["ResumeSection"],
          extraParams: [],
        },
        {
          name: "Resume Form",
          handle: "studentApplicationStage2Step2",
          showSections: ["ResumeSection"],
          extraParams: [],
        },
        {
          name: "Head Shot Form",
          handle: "studentApplicationStage2Step2",
          showSections: ["HeadshotSection"],
          extraParams: [],
        },
        {
          name: "Head Shot",
          handle: "studentApplicationStage2Step2",
          showSections: ["HeadshotSection"],
          extraParams: [],
        },
        {
          name: "Head Shot",
          handle: "studentApplicationStage2Step2",
          showSections: ["HeadshotSection"],
          extraParams: [],
        },
        {
          name: "JCU Privacy Release",
          handle: "studentApplicationStage2Step2",
          showSections: ["JohnCabotPrivacyReleaseSection"],
          extraParams: [],
        },
        {
          name: "LdM Supplemental Application Form",
          handle: "studentApplicationStage2Step2",
          showSections: ["LdMRegistrationPolicySection"],
          extraParams: [],
        },
        {
          name: "Passport Info",
          handle: "studentApplicationStage2Step2",
          showSections: [
            "passportInformation",
            "html",
            "noPassportBox",
            "html1",
          ],
          extraParams: [],
        },
        {
          name: "Course Selection",
          handle: "studentApplicationStage2Step2",
          showSections: ["courses"],
          extraParams: [],
        },
        {
          name: "8-Week Fixed Date Internship Credit Options",
          handle: "studentApplicationStage2Step1",
          showSections: [
            "internshipCreditOptions",
            "flexDateInternship1",
            "fixedDateWell",
            "pleaseIndicateYourPreferredCreditOptionBelow1",
          ],
          removeSections: ["requiredSignature"],
          extraParams: [],
        },
        {
          name: "Flex-Date Internship Academic Credit Option",
          handle: "studentApplicationStage2Step1",
          showSections: [
            "internshipCreditOptions",
            "flexDateInternship",
            "flexDateWell",
            "pleaseIndicateYourPreferredCreditOptionBelow",
          ],
          removeSections: ["requiredSignature"],
          extraParams: [],
        },
        {
          name: "University Approval",
          handle: "studentApplicationStage2Step1",
          showSections: ["UniversityApproval", "OtherAdvisors"],
          extraParams: [],
        },
        {
          name: "University Approval Form",
          handle: "studentApplicationStage2Step1",
          showSections: ["UniversityApproval", "OtherAdvisors"],
          extraParams: [],
        },
        {
          name: "Web Form Letter of Recommendation",
          handle: "studentApplicationStage2Step1",
          showSections: [
            "LettersOfRecommendationSection",
            "LettersofRecommendationForm",
            "LettersofRecommendationForm2",
          ],
          extraParams: [],
        },
        {
          name: "E-Transcript",
          handle: "studentApplicationStage2Step1",
          showSections: ["E-Transcript-Section"],
          extraParams: [],
        },
        {
          name: "GPA Statement",
          handle: "studentApplicationStage2Step1",
          showSections: ["GpaStatementSection"],
          extraParams: [
            {
              formioComponentName: "gpa",
              by: "assignValue",
              value: 2.7,
            },
          ],
        },
        {
          name: "Unofficial Transcript",
          handle: "studentApplicationStage2Step1",
          showSections: ["UnofficialTranscriptSection"],
          extraParams: [],
        },
        {
          name: "Unofficial Transcript Form",
          handle: "studentApplicationStage2Step1",
          showSections: ["UnofficialTranscriptSection"],
          extraParams: [],
        },
        {
          name: "Statement of Purpose",
          handle: "studentApplicationStage2Step1",
          showSections: ["StatementOfPurposeSection"],
          extraParams: [
            {
              formioComponentName: "StatementofPurposeText",
              by: "method",
              method: "setStatementOfPurpose",
            },
          ],
        },
        {
          name: "Grade Report",
          handle: "studentApplicationStage2Step1",
          showSections: ["GradeReportSection"],
          extraParams: [],
        },
        {
          name: "Academic Information",
          handle: "studentApplicationStage2Step1",
          showSections: ["page1Panel", "html"],
          extraParams: [],
        },
        {
          name: "Grade Report Form",
          handle: "studentApplicationStage2Step1",
          showSections: ["GradeReportSection"],
          extraParams: [],
        },
        {
          name: "Paper Transcript",
          handle: "studentApplicationStage2Step1",
          showSections: ["TranscriptSection"],
          extraParams: [],
        },
        {
          name: "Cover Letter",
          handle: "hostUniversityInternship",
          showSections: ["panel2"],
          extraParams: [],
        },
        {
          name: "Cover Letter Form",
          handle: "hostUniversityInternship",
          showSections: ["panel2"],
          extraParams: [],
        },
        {
          name: "Internship Interests",
          handle: "hostUniversityInternship",
          showSections: ["panel3"],
          extraParams: [],
        },
        {
          name: "Internship Interest",
          handle: "hostUniversityInternship",
          showSections: ["panel3"],
          extraParams: [],
        },
        {
          name: "Internship Interest Form",
          handle: "hostUniversityInternship",
          showSections: ["panel3"],
          extraParams: [],
        },
        {
          name: "File Upload Letter of Recommendation",
          handle: "hostUniversityInternship",
          showSections: ["panel", "panelColumns"],
          extraParams: [],
        },
        {
          name: "File Upload Letter of Recommendation Form",
          handle: "hostUniversityInternship",
          showSections: ["panel", "panelColumns"],
          extraParams: [],
        },
        {
          name: "Health And Wellness",
          handle: "healthwellnessform",
          showSections: ["panel1", "html7", "html1", "formContainer"],
          extraParams: [],
        },
        {
          name: "Health and Wellness",
          handle: "healthwellnessform",
          showSections: ["panel1", "html7", "html1", "formContainer"],
          extraParams: [],
        },
        {
          name: "Housing",
          handle: "housing",
          showSections: [
            "html",
            "accommodationStylePreference",
            "singleRoomPreference",
            "requestingARoommate3",
            "decliningARoommate",
            "requestingAHousemate",
            "rankingYourHousePreference",
            "housingQuestionnare",
          ],
          extraParams: [],
        },
        {
          name: "Accommodation Style Preference",
          handle: "housing",
          showSections: ["html", "accommodationStylePreference"],
          extraParams: [],
        },
        {
          name: "Single Room Preference",
          handle: "housing",
          showSections: ["html", "singleRoomPreference"],
          extraParams: [],
        },
        {
          name: "Requesting a Roommate",
          handle: "housing",
          showSections: ["html", "requestingARoommate3"],
          extraParams: [],
        },
        {
          name: "Housing Questionnaire",
          handle: "housing",
          showSections: ["html", "housingQuestionnare"],
          extraParams: [],
        },
        {
          name: "Declining a Roommate",
          handle: "housing",
          showSections: ["html", "decliningARoommate"],
          extraParams: [],
        },
        {
          name: "Requesting a Housemate",
          handle: "housing",
          showSections: ["html", "requestingAHousemate"],
          extraParams: [],
        },
        {
          name: "Ranking Your Housing Preference",
          handle: "housing",
          showSections: ["html", "rankingYourHousePreference"],
          extraParams: [],
        },
        {
          name: "Housing Form",
          handle: "housing",
          showSections: [
            "html",
            "accommodationStylePreference",
            "singleRoomPreference",
            "requestingARoommate3",
            "decliningARoommate",
            "requestingAHousemate",
            "rankingYourHousePreference",
            "housingQuestionnare",
          ],
          extraParams: [],
        },
        {
          name: "Travel Arrangements",
          handle: "travelArrangements",
          showSections: ["columns1"],
          extraParams: [],
        },
        {
          name: "Virtual Participant Agreement",
          handle: "participantAgreementVirtual",
          showSections: [""],
          extraParams: [],
        },
        {
          name: "Study and Intern Participant Agreement",
          handle: "participantAgreementStudyIntern",
          showSections: [""],
          extraParams: [],
        },
      ],
      sanitizeAttributes: [
        "focusable",
        "viewBox",
        "fill",
        "stroke",
        "stroke-width",
        "stroke-linecap",
        "stroke-linejoin",
        "cx",
        "cy",
        "r",
        "x1",
        "x2",
        "y1",
        "y2",
        "points",
        "d",
      ],
      tagsAttributes: ["svg", "circle", "line", "polyline", "path"],
    };
  },
};
