<template>
  <fieldset>
    <label class="block">
      <ApiTextArea
        name="additionalHealth"
        label="Is there any additional health or medical information that would be helpful for API to be aware of during your experience abroad? If so, please explain here:"
        :typed-text="typedText"
        :max-length="500"
        :validation-errors="v$.value.textarea"
        @textUpdate="onFieldChange('textarea', $event)"
      />
    </label>
  </fieldset>
</template>

<script>
import formInputs from "../../mixins/formInputs";
import ApiTextArea from "../forms/SharedComponents/ApiTextArea";
import { maxLength } from "@vuelidate/validators";
import validationMessages from "../../mixins/validationMessages";
import useVuelidate from "@vuelidate/core";

export default {
  name: "AdditionalHealthInformation",
  components: { ApiTextArea },
  mixins: [formInputs, validationMessages],
  props: {
    health: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:additional-health"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        textarea: "",
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:additional-health", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  validations: {
    value: {
      textarea: {
        maxLength: maxLength(500),
      },
    },
  },
};
</script>
