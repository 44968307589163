export const priceBreakDownMixin = {
  methods: {
    async getPriceBreakDown(sessionId) {
      let response = await fetch(
        this.trailingSlash(process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT) +
          "session/" +
          sessionId
      );
      let status = response.status;

      if (status == 200) {
        response = await response.json();
        let housingFee = response.housing_fee;
        let educationalFee = response.educational_fee
          ? response.educational_fee
          : "0.00";
        let medicalFee = response.insurance_fee
          ? response.insurance_fee
          : "0.00";
        let confirmationFee = response.confirmation_payment
          ? response.confirmation_payment
          : "0.00";
        let applicationFee = response.application_fee
          ? response.application_fee
          : "0.00";

        let totals = {
          educationalFee: this.totalEducationalFee(
            educationalFee,
            confirmationFee,
            applicationFee
          ),
          housingFee: parseFloat(housingFee),
          medicalFee: parseFloat(medicalFee),
          total: 0,
        };

        totals.total = this.total(
          housingFee,
          totals.educationalFee,
          medicalFee
        );

        return totals;
      }
    },
    totalEducationalFee(educationalFee, confirmationFee, applicationFee) {
      return (
        parseFloat(educationalFee) +
        parseFloat(confirmationFee) +
        parseFloat(applicationFee)
      );
    },
    total(housingFee, educationalFee, medicalFee) {
      return (
        parseFloat(housingFee) +
        parseFloat(educationalFee) +
        parseFloat(medicalFee)
      );
    },
  },
};
