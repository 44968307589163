<template>
  <div class="w-full px-4 pb-4 mx-auto max-w-7xl sm:px-8 sm:pb-8">
    <div v-if="showHeader" class="my-4">
      <slot name="pageHeader">
        <a
          href="/applications"
          class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left w-4 h-4"
          >
            <polyline points="15 18 9 12 15 6" />
          </svg>
          <span class="ml-1">View Application</span>
        </a>
      </slot>
    </div>
    <div
      class="px-4 py-6 -mx-4 bg-white border-t border-gray-200 sm:-mx-8 md:mx-auto sm:px-8 md:p-12 md:border md:rounded"
    >
      <slot name="pageContent"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageDefaultDesign",
  props: {
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
