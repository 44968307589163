export default {
  data() {
    return {
      files: [],
      filesOnProgress: false,
      fileIputHasErrors: false,
    };
  },
  methods: {
    updateProgress(val) {
      this.filesOnProgress = val;
    },
    updateFiles(files, error) {
      this.files = files;
      this.fileIputHasErrors = error;
    },
  },
};
