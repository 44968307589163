<template>
  <div v-if="showCard" class="flex flex-col">
    <router-link
      :to="{
        name: 'noinsuranceorpayagreement',
        params: { applicationId: enrollmentApplicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
    >
      <CheckMarkIcon :completed="completed" />
      <p
        class="text-sm font-semibold ml-4 ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Custom Participant Agreement
      </p>
    </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
import formIoApi from "@/mixins/formIoApi.js";
import CheckMarkIcon from "@/components/forms/SharedComponents/CheckMarkIcon.vue";
import { eventBus } from "@/app";
import { POST_FORM_RULES } from "@/constants";

export default {
  name: "NoInsuranceOrPayCard",
  components: { CheckMarkIcon },
  mixins: [formIoApi],
  data() {
    return {
      showCard: false,
      formURL: "participantagreementnoinsuranceordirectpay",
    };
  },
  computed: {
    ...mapState(["program", "currentApplicationSubmissions"]),
    completed() {
      return Object.hasOwn(this.currentApplicationSubmissions, this.formURL);
    },
    enrollmentApplicationId() {
      return this.$route.params.applicationId;
    },
  },
  watch: {
    program: {
      handler: function (val) {
        this.setCardProps(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.setCardProps(this.program);
  },
  methods: {
    setCardProps(program) {
      const formRules = program.post_acceptance_form_rules;
      this.showCard = false;

      if (formRules && formRules.length) {
        this.showCard = formRules.includes(
          "CP Participant Agreement Form - No Insurance or Direct Pay"
        );
        if (this.showCard) {
          eventBus.$emit("addPreDepartureForm", {
            label: "Custom Participant Agreement",
            ruleName:
              POST_FORM_RULES[
                "CP Participant Agreement Form - No Insurance or Direct Pay"
              ],
            routeName: "noinsuranceorpayagreement",
            submitted: this.completed,
          });
        }
      }
    },
  },
};
</script>
