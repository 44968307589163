<template>
  <div class="flex flex-col items-center space-y-16">
    <p class="text-3xl">Adding Students In Progress</p>
    <progress-bar
      :is-open="true"
      :total="total"
      :submitted="processed"
      :is-not-a-form-process="true"
    ></progress-bar>
    <p class="text-lg md:text-lg">
      Please wait while students are being added.
    </p>
  </div>
</template>

<script>
import FormCounter from "../forms/SharedComponents/FormCounter.vue";
import axios from "axios";
export default {
  name: "LoadingScreen",
  components: {
    "progress-bar": FormCounter,
  },
  props: {
    bulkEnrollmentId: {
      type: [Number, String],
      default: null,
    },
  },
  emits: ["next-btn"],
  data() {
    return {
      error: false,
      total: 0,
      processed: 0,
      failed: 0,
      succeeded: 0,
      pollFinished: false,
    };
  },
  watch: {
    pollFinished(newStatus) {
      if (newStatus) {
        this.$emit("next-btn", {
          processResult: {
            failed: this.failed,
            succeeded: this.succeeded,
            total: this.total,
            error: this.error,
          },
        });
      }
    },
  },
  mounted() {
    this.beginProcessing();
    this.startPolling();
  },
  methods: {
    async wait(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async startPolling() {
      while (!this.pollFinished) {
        await this.wait(1000);
        this.pollData();
        if (this.processed === Number(this.total) && this.total > 0) {
          this.pollFinished = true;
        }
      }
    },
    beginProcessing() {
      axios.patch(`/api/bulkenrollment/${this.bulkEnrollmentId}/process`);
    },
    async pollData() {
      await axios
        .get(`/api/bulkenrollment/${this.bulkEnrollmentId}`)
        .then((response) => response.data)
        .then((data) => (data.code === 200 ? data.result : false))
        .then((bulkEnrollmentMetrics) => {
          if (!bulkEnrollmentMetrics) {
            throw "Not started yet";
          }
          this.total = Number(bulkEnrollmentMetrics.total_rows);
          this.succeeded = Number(bulkEnrollmentMetrics.succeeded_rows);
          this.failed = Number(bulkEnrollmentMetrics.failed_rows);
          this.processed =
            Number(bulkEnrollmentMetrics.succeeded_rows) +
            Number(bulkEnrollmentMetrics.failed_rows);
        })
        .catch((e) => {
          this.error = e;
        });
    },
  },
};
</script>
