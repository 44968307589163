<template>
  <Checkbox
    :label="label"
    name="priorAcceptanceCheckbox"
    :checked="checked"
    :validation-errors="v$.value.checkedOption"
    @input="onFieldChange('checkedOption', $event)"
  />
</template>

<script>
import Checkbox from "../forms/SharedComponents/CheckBox.vue";
import formInputs from "../../mixins/formInputs";
import useVuelidate from "@vuelidate/core";
const validateEquality = (value) => {
  return value === true;
};

export default {
  name: "PriorAcceptance",
  components: { Checkbox },
  mixins: [formInputs],
  props: {
    priorAcceptance: {
      type: Object,
      default: function () {
        return {};
      },
    },
    checked: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: "",
    },
  },
  emits: ["update:prior-acceptance"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        checkedOption: false,
      },
    };
  },
  computed: {
    label() {
      let statuses = ["Application Started", "Ready for Review"];
      let copy = "";
      if (statuses.includes(this.status))
        copy =
          "I understand that I am not obligated to provide this medical information prior to my acceptance to the program. If I do choose to complete the form before I am accepted, I understand that the answers I give will not be used as part of my admissions decision and my responses are not reviewed prior to the review of my application. ";
      copy +=
        "API is committed to providing enriching international opportunities for all participants. API does not discriminate against any individual based on ethnicity, nationality, ancestry, race, sex, gender identity or expression, creed, age, marital status, sexual orientation, or disability in its admissions decisions, program activities or employment practices.";
      return copy;
    },
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:prior-acceptance", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {
    value: {
      checkedOption: {
        sameAs: validateEquality,
      },
    },
  },
};
</script>
