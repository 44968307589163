<template>
  <div class="relative flex items-start" :class="containerClass">
    <div class="flex h-5 items-center">
      <input
        v-model="boxChecked"
        :name="name"
        type="checkbox"
        class="h-4 w-4 rounded border-gray-300 text-blue-400 focus:ring-2"
        :data-cy="dataCy"
      />
    </div>
    <div class="ml-3 text-sm">
      <label :for="name" class="font-medium text-gray-700">{{ label }}</label>
    </div>
    <div v-for="(value, rule) in sortedValidationRules" :key="rule">
      <div
        v-if="validationErrors[rule].$invalid"
        class="error text-error-900"
        :class="{ hidden: !validationErrors.$error }"
      >
        {{
          rule === "sameAs"
            ? validationMessage[rule].acceptCheckbox
            : "Checkbox error"
        }}
      </div>
    </div>
  </div>
</template>
<script>
import nestedModelComponent from "../../../mixins/nestedModelComponent";
import validationMessages from "../../../mixins/validationMessages";
export default {
  mixins: [nestedModelComponent, validationMessages],
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
    srOnlyLegend: {
      type: String,
      default: "",
    },
    dataCy: {
      type: String,
      default: "",
    },
    containerClass: {
      type: String,
      default: "",
    },
  },
  emits: ["input"],
  data() {
    return {
      boxChecked: "",
    };
  },
  watch: {
    boxChecked: function (newVal) {
      this.$emit("input", newVal);
    },
    checked: function () {
      this.boxChecked = this.checked;
    },
  },
  created() {
    if (this.checked) {
      this.boxChecked = this.checked;
    }
  },
};
</script>
<style scoped>
/* The scoped styles here are used to overrider firefox and mozilla default styling for checkboxed */
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 2px;
}
input[type="checkbox"]:checked {
  border-color: transparent;
  background-color: #2f6095;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}
</style>
