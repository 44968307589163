<template>
  <ul>
    <li
      v-for="(item, index) in items"
      :key="index"
      class="border-b border-gray-200 mb-5"
    >
      <div class="relative">
        <div class="items-start justify-between mb-6 sm:flex">
          <div class="flex flex-wrap items-center w-full pr-10">
            <h3 class="text-gray-700 mr-4 text-xl font-semibold">
              {{ item.firstName }} {{ item.lastName }}
            </h3>
            <div class="flex items-center mt-2">
              <span
                v-if="item.role"
                class="inline-block px-2 py-px text-sm text-gray-600 bg-white border border-gray-600 rounded-sm"
              >
                <span>{{ transformString(item.role) }}</span>
              </span>
              <span
                v-if="item.relationship"
                class="inline-block px-2 py-px text-sm text-gray-600 bg-white border border-gray-600 rounded-sm"
              >
                <span>{{ transformString(item.relationship) }}</span>
              </span>
              <template v-for="(icon, iconIndex) in ICONS" :key="iconIndex">
                <div v-if="item[icon.propName]" class="flex-none ml-4">
                  <img alt="" :src="icon.svg" />
                </div>
              </template>
            </div>
          </div>
          <div class="flex absolute right-0">
            <button
              class="flex items-center justify-center w-8 h-8 text-gray-600"
              @click="$emit('delete', item.id)"
            >
              <i class="fa fa-trash"></i>
            </button>
            <button
              class="flex items-center justify-center w-8 h-8 text-gray-600"
              @click="$emit('edit', item.id)"
            >
              <span class="sr-only">Edit</span>
              <svg
                class="feather feather-edit-2 w-4 h-4"
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
                height="24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <table class="w-full table-fixed">
          <tbody class="text-sm text-left">
            <template
              v-for="(value, propName, propIndex) in item"
              :key="propIndex"
            >
              <tr
                v-if="
                  propName === 'legalGuardian' ||
                  (value && !invalidProps.includes(propName))
                "
                class="block my-4 xs:my-0 xs:table-row"
              >
                <td
                  class="block text-xs font-semibold tracking-widest text-gray-500 uppercase xs:py-2 xs:pr-2 xs:table-cell"
                >
                  {{
                    propName === "legalGuardian"
                      ? "Role"
                      : transformString(propName)
                  }}
                </td>
                <td class="block xs:py-2 xs:pl-2 xs:table-cell text-gray-700">
                  <span v-if="propName === 'phone'">
                    {{ item.phoneCode + item.phone }}
                  </span>
                  <template v-else-if="propName === 'legalGuardian'">
                    <template
                      v-for="(icon, iconIndex) in ICONS"
                      :key="iconIndex"
                    >
                      <span
                        v-if="item[icon.propName]"
                        class="flex items-center mr-4"
                      >
                        <img alt="" :src="icon.svg" class="mr-2" />
                        <span> {{ icon.label }}</span>
                      </span>
                    </template>
                  </template>
                  <span v-else> {{ getPropValue(propName, value) }} </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </li>
  </ul>
</template>

<script>
const icons = [
  {
    propName: "legalGuardian",
    label: "Legal Guardian",
    svg: "/images/btn-legal-guardian.svg",
  },
  {
    propName: "emergencyContact",
    label: "Emergency Contact",
    svg: "/images/btn-emergency.svg",
  },
  {
    propName: "billingContact",
    label: "Billing Contact",
    svg: "/images/btn-billing-contact.svg",
  },
];

export default {
  name: "DetailView",
  props: {
    items: {
      type: Array,
      default: function () {
        return new Array();
      },
    },
  },
  emits: ["delete", "edit"],
  data() {
    return {
      invalidProps: [
        "id",
        "shareInformation",
        "emergencyContact",
        "billingContact",
        "phoneCode",
        "isValidPhoneNumber",
        "submitError",
      ],
    };
  },
  created() {
    this.setConstants();
  },
  methods: {
    transformString(str) {
      let newString = str.replace(/([A-Z])/g, " $1");
      return newString.charAt(0).toUpperCase() + newString.slice(1);
    },
    getPropValue(prop, value) {
      return prop === "relationship" || prop === "role"
        ? this.transformString(value)
        : value;
    },
    setConstants() {
      this.ICONS = icons;
    },
  },
};
</script>
