<template>
  <PageStyle>
    <template #pageHeader>
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </template>
    <template #pageContent>
      <FormPanel
        :id="'InternshipLetter'"
        :title="'Letter of recommendation'"
        class="mt-6"
        panel-content-class="text-gray-600 border-gray-200"
      >
        <template #content>
          <div class="mb-6 space-y-6">
            <p data-cy="internship-letter1">
              The internship program requires you to submit two (2) typed
              letters of recommendation, preferably on company or university
              letterhead. The letters of recommendation can be academic or
              professional in nature (e.g. from a past or current employer or
              professor.)
            </p>

            <p data-cy="internship-letter2">
              The person writing the letter should indicate in the body of the
              letter why they believe you would be a good fit for the internship
              program, noting any relevant skills or academic knowledge you have
              in the field of study or industry in which you are seeking an
              internship, and state what they believe to be your overall level
              of maturity and probability of success in an international
              internship.
            </p>

            <p data-cy="internship-letter3">
              All letters of reference must be in English.
            </p>
            <p data-cy="internship-letter4">
              Enter the name and email of the individuals writing your
              recommendation below and we will send them a link to upload the
              recommendation.
            </p>
            <EmailSender
              ref="FirstProfessor"
              :email-information="professors.first"
              :disable-button="false"
              :button-label="firstButtonLabel"
              :email-data="firstSenderData"
              :email-u-r-l="emailSenderURL"
              :application-id="applicationId"
              :selected-name="formioData.first.name"
              :selected-email="formioData.first.email"
              label-prefix="firstProfessor"
              placeholder-name="Teacher or Professor Name"
              placeholder-email="Teacher or Professor Email"
              :success-submission="firstSuccessSubmission"
              @validateForm="validateFormFirst"
              @submitForm="submitFirst"
              @update:email-sender="professors.first = $event"
            >
              <template v-if="firstError" #errors>
                <p>{{ firstError }}</p>
              </template>
            </EmailSender>

            <EmailSender
              ref="SecondProfessor"
              :email-information="professors.second"
              :disable-button="false"
              :button-label="secondButtonLabel"
              :email-data="secondSenderData"
              :email-u-r-l="emailSenderURL"
              :application-id="applicationId"
              :selected-name="formioData.second.name"
              :selected-email="formioData.second.email"
              label-prefix="secondProfessor"
              placeholder-name="Teacher or Professor Name"
              placeholder-email="Teacher or Professor Email"
              :success-submission="secondSuccessSubmission"
              @validateForm="validateFormSecond"
              @submitForm="submitSecond"
              @update:email-sender="professors.second = $event"
            >
              <template v-if="secondError" #errors>
                <p>{{ secondError }}</p>
              </template>
            </EmailSender>
          </div>
        </template>
      </FormPanel>
    </template>
  </PageStyle>
</template>

<script>
import { mapState } from "vuex";
import FormPanel from "../SharedComponents/panel.vue";
import EmailSender from "../SharedComponents/EmailSender.vue";
import PageStyle from "../SharedComponents/Layout/PageDefault.vue";
import formIoApi from "../../../mixins/formIoApi.js";
import forms from "../../../mixins/forms.js";

export default {
  name: "InternshipLetter",
  components: {
    FormPanel,
    EmailSender,
    PageStyle,
  },
  mixins: [formIoApi, forms],
  data() {
    return {
      professors: {
        first: {
          name: "",
          email: "",
        },
        second: {
          name: "",
          email: "",
        },
      },
      formioData: {
        first: {
          name: "",
          email: "",
        },
        second: {
          name: "",
          email: "",
        },
      },
      firstButtonLabel: "Send Request",
      secondButtonLabel: "Send Request",
      formURL: "hostuniversityinternship",
      submissionId: "",
      firstSuccessSubmission: false,
      secondSuccessSubmission: false,
      firstError: "",
      secondError: "",
    };
  },
  computed: {
    ...mapState(["selectedProgramId"]),
    applicationId() {
      return this.$route.params.applicationId;
    },
    emailSenderURL() {
      return (
        this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
        "recommendation"
      );
    },
    firstSenderData() {
      let body = new FormData();
      body.append("program_session_id", this.selectedProgramId);
      body.append("application_id", this.applicationId);
      body.append("email", this.professors.first.email);
      body.append("name", this.professors.first.name);
      return body;
    },
    secondSenderData() {
      let body = new FormData();
      body.append("program_session_id", this.selectedProgramId);
      body.append("application_id", this.applicationId);
      body.append("email", this.professors.second.email);
      body.append("name", this.professors.second.name);
      return body;
    },
  },
  watch: {
    "professors.first": {
      handler: function () {
        if (this.firstSuccessSubmission) this.firstSuccessSubmission = false;
      },
      deep: true,
    },
    "professors.second": {
      handler: function () {
        if (this.secondSuccessSubmission) this.secondSuccessSubmission = false;
      },
      deep: true,
    },
  },
  async created() {
    const valid = await this.isValidApplication(
      this.applicationId,
      "File Upload Letter of Recommendation"
    );

    if (valid) {
      this.getFormioData();
    } else {
      window.location.href = "/applications";
    }
  },
  methods: {
    getFormioData() {
      this.formioSubmissionExists(this.formURL, this.applicationId).then(
        (submissionId) => {
          this.submissionId = submissionId;
          if (this.submissionId) {
            this.getFormioSubmission(this.formURL, this.submissionId).then(
              (response) => {
                this.setFormioData(response);
              }
            );
          }
        }
      );
    },
    setFormioData(data) {
      this.formioData.first.name = data["recommendation-name-01"]
        ? data["recommendation-name-01"]
        : "";
      this.formioData.first.email = data["recommendation-email-01"]
        ? data["recommendation-email-01"]
        : "";

      this.formioData.second.name = data["recommendation-name-02"]
        ? data["recommendation-name-02"]
        : "";
      this.formioData.second.email = data["recommendation-email-02"]
        ? data["recommendation-email-02"]
        : "";

      this.firstButtonLabel = data.letterSubmitted01
        ? "Resend"
        : this.firstButtonLabel;
      this.secondButtonLabel = data.letterSubmitted02
        ? "Resend"
        : this.secondButtonLabel;
    },
    validateFormFirst() {
      this.firstSuccessSubmission = false;
      this.firstError = "";
      this.$refs.FirstProfessor.submit();
    },
    validateFormSecond() {
      this.secondSuccessSubmission = false;
      this.secondError = "";
      this.$refs.SecondProfessor.submit();
    },
    submitFirst() {
      this.submitForm("first");
    },
    submitSecond() {
      this.submitForm("second");
    },
    submitForm(section) {
      if (this.submissionId) {
        this.updateLetter(section);
      } else {
        this.createLetter(section);
      }
    },
    updateLetter(section) {
      const jsonData = [];

      if (section === "first") {
        jsonData.push({
          op: "add",
          path: "/data/letterSubmitted01",
          value: true,
        });
        jsonData.push({
          op: "add",
          path: "/data/recommendation-name-01",
          value: this.professors.first.name,
        });
        jsonData.push({
          op: "add",
          path: "/data/recommendation-email-01",
          value: this.professors.first.email,
        });
      } else {
        jsonData.push({
          op: "add",
          path: "/data/letterSubmitted02",
          value: true,
        });
        jsonData.push({
          op: "add",
          path: "/data/recommendation-name-02",
          value: this.professors.second.name,
        });
        jsonData.push({
          op: "add",
          path: "/data/recommendation-email-02",
          value: this.professors.second.email,
        });
      }

      this.submitToFormIo(this.formURL, jsonData, "PATCH", this.submissionId)
        .then(() => {
          this[section + "ButtonLabel"] = "Resend";
          this[section + "SuccessSubmission"] = true;
        })
        .catch((error) => {
          this[section + "Error"] = error;
        })
        .finally(() => {
          if (section === "first")
            this.$refs.FirstProfessor.$refs.firstProfessorButton.stopLoading();
          else
            this.$refs.SecondProfessor.$refs.secondProfessorButton.stopLoading();
        });
    },
    createLetter(section) {
      this.submitToFormIo(this.formURL, this.getFormioObject(section))
        .then((response) => {
          this.submissionId = response;
          this[section + "ButtonLabel"] = "Resend";
          this[section + "SuccessSubmission"] = true;
        })
        .catch((error) => {
          this[section + "Error"] = error;
        })
        .finally(() => {
          if (section === "first")
            this.$refs.FirstProfessor.$refs.firstProfessorButton.stopLoading();
          else
            this.$refs.SecondProfessor.$refs.secondProfessorButton.stopLoading();
        });
    },
    getFormioObject(section) {
      let data = {
        data: {
          application_id: this.applicationId,
        },
      };

      if (section === "first") {
        data.data.letterSubmitted01 = true;
        data.data["recommendation-name-01"] = this.professors.first.name;
        data.data["recommendation-email-01"] = this.professors.first.email;
      } else {
        data.data.letterSubmitted02 = true;
        data.data["recommendation-name-02"] = this.professors.second.name;
        data.data["recommendation-email-02"] = this.professors.second.email;
      }

      return data;
    },
  },
};
</script>
