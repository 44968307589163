<template>
  <div v-if="showParticipantAgreementIDTile" class="flex flex-col">
    <router-link
      :to="{
        name: 'custom-participant-agreement-i-d',
        params: { applicationId: applicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
    >
      <CheckMarkIcon :completed="completed" />
      <p
        class="text-sm font-semibold ml-4 ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Custom Participant Agreement
      </p>
    </router-link>
  </div>
</template>

<script>
import CheckMarkIcon from "@/components/forms/SharedComponents/CheckMarkIcon.vue";
import formIoApi from "@/mixins/formIoApi.js";
import postFormRules from "@/mixins/postFormRules.js";
import { mapState } from "vuex";
import { eventBus } from "@/app";

export default {
  name: "ParticipantAgreementCard",
  components: { CheckMarkIcon },
  mixins: [formIoApi, postFormRules],
  data() {
    return {
      formURL: "cpparticipantagreementinsurancedirectpay",
    };
  },
  computed: {
    ...mapState(["program", "currentApplicationSubmissions"]),
    completed() {
      return Object.hasOwn(this.currentApplicationSubmissions, this.formURL);
    },
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  async mounted() {
    if (this.showParticipantAgreementIDTile) {
      eventBus.$emit("addPreDepartureForm", {
        label: "Custom Participant Agreement",
        ruleName: "CP Participant Agreement Form - Insurance & Direct Pay",
        routeName: "custom-participant-agreement-i-d",
        submitted: this.completed,
      });
    }
  },
};
</script>
