import { mapMutations, mapState } from "vuex";
import formIoApi from "./formIoApi";
import { trailingSlash } from "../mixins/helpers";
import formioHelpers from "./formioHelpers";

const enrollmentApiUrlBase = trailingSlash(
  process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT
);

export default {
  mixins: [formIoApi, formioHelpers],
  data() {
    return {
      studentGpa: "",
    };
  },
  computed: {
    ...mapState([
      "formioToken",
      "program",
      "studentApplications",
      "currentApplicationId",
    ]),
    formioGpa() {
      let gpa = 0;
      //find the form.io id of the academic profile
      let academicForm = this.forms.filter((form) => {
        return form.slug === "academic-profile";
      });
      //get students reported gpa in formi.io submissions
      this.studentApplications.filter((application) => {
        if (application.id === this.currentApplicationId) {
          application.submissions.filter((submission) => {
            if (submission.form === academicForm[0].id) {
              gpa = submission.data.gpa;
            }
          });
        }
      });
      return gpa;
    },
  },
  methods: {
    ...mapMutations(["setCurrentGpa"]),
    async setStudentGpa() {
      await fetch(enrollmentApiUrlBase + "student", {
        headers: {
          "Content-type": "application/json",
          "x-jwt-token": this.formioToken,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return false;
          }
        })
        .then((result) => {
          this.studentGpa = !isNaN(parseFloat(result.gpa))
            ? parseFloat(result.gpa)
            : 0;
          this.setCurrentGpa(this.studentGpa);
        })
        .catch((error) => console.log(error));
    },
    async loadProgramData(applicationID) {
      await this.$store.dispatch("setProgramDataByApplicationId", {
        applicationId: applicationID,
        apolloClient: this.$apollo,
      });
    },
    async showGpaStatement() {
      //if minimum gpa is null, dont show form
      if (!this.program.minimum_gpa) {
        return false;
      }
      //If the students GPA is greater than or equal to the GPA in the program session then the student does not need to complete the gpa statement
      switch (this.studentGpa > 0) {
        case false:
          if (this.formioGpa >= this.program.minimum_gpa) {
            return false;
          }
          break;

        default:
          if (this.studentGpa >= this.program.minimum_gpa) {
            return false;
          }
          break;
      }

      //If the students GPA is less than the GPA in the program session then the student should see the gpa statement form.
      return true;
    },
    async showLetterOfRecommendation() {
      //form rules requests the letter of recommendation form
      if (
        this.program.form_rules.includes("Web Form Letter of Recommendation")
      ) {
        return true;
      }

      //if there is no minumum gpa for  the program dont show the form
      if (!this.program.minimum_gpa) {
        return false;
      }

      //If the students GPA is greater than or equal to the GPA in the program session then the student does not need to complete the letter of recommendation form.
      switch (this.studentGpa > 0) {
        case false:
          if (this.formioGpa >= this.program.minimum_gpa) {
            return false;
          }
          break;

        default:
          if (this.studentGpa >= this.program.minimum_gpa) {
            return false;
          }
          break;
      }

      //If the students GPA is less than the GPA in the program session then the student should see the study abroad letter of recommendation form.
      return true;
    },
  },
};
