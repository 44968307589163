<template>
  <div class="mt-1 text-sm md:col-span-2 md:mt-0 lg:col-span-2">
    Balance: <b class="font-semibold text-gray-650">${{ balance }}</b>
  </div>
</template>

<script>
export default {
  props: {
    balance: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
