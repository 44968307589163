<template>
  <div class="w-full px-4 pb-4 mx-auto max-w-7xl sm:px-8 sm:pb-8">
    <div class="my-4">
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: enrollmentApplicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </div>
    <div
      class="px-4 py-6 -mx-4 bg-white border-t border-gray-200 sm:-mx-8 md:mx-auto sm:px-8 md:p-12 md:border md:rounded"
    >
      <h1 class="mb-2 text-xl md:font-semibold md:text-2xl">
        Academic Information
      </h1>
      <div
        v-for="rule in rules"
        :id="rule.url"
        :key="rule.url"
        class="mt-10 relative"
      />
    </div>
  </div>
</template>

<script>
import academicInformationRules from "../../mixins/academicInformationRules.js";
import { Formio } from "formiojs";
import tailwind from "@apiabroad/formio-tailwind-template";
import forms from "../../mixins/forms";
import impersonationFormSniffer from "../../mixins/ImpersonationFormSniffer";
import formService from "../../services/form";

export default {
  name: "AcademicInformation",
  mixins: [academicInformationRules, forms, impersonationFormSniffer],
  mounted() {
    this.$nextTick(() => {
      if (this.validateApplication()) {
        this.setPageData();
      }
    });

    if (!this.isApplicationOwner(this.enrollmentApplicationId)) {
      //redirect to my applications
      this.$router.push({
        name: "applications",
      });
    }
  },
  methods: {
    setPageData() {
      this.setAllForm(this.program);
      this.getAllSubmission().then(() => {
        this.createAllForms();
      });
    },
    createAllForms() {
      this.validRules.forEach((rule) => {
        this.createForm(rule.submission._id, rule.url);
      });
    },
    createForm(submissionId, url) {
      let formURL = `/api/forms/${url}/submission`;
      if (submissionId) {
        formURL += "/" + submissionId;
      }

      Formio.setBaseUrl(`${window.location.protocol}//${window.location.host}`);
      Formio.setAuthUrl(
        `${window.location.protocol}//${window.location.host}/api/forms`
      );
      Formio.use(tailwind);
      Formio.createForm(document.getElementById(url), formURL, {
        hooks: {
          beforeSubmit: (submission, next) => {
            submission.data.application_id = this.enrollmentApplicationId;
            submission = this.attachEmployeeToSubmission(submission, "post");
            next();
          },
        },
        noAlerts: true,
        sanitizeConfig: {
          addAttr: [
            "focusable",
            "viewBox",
            "fill",
            "stroke",
            "stroke-width",
            "stroke-linecap",
            "stroke-linejoin",
            "cx",
            "cy",
            "r",
            "x1",
            "x2",
            "y1",
            "y2",
            "points",
            "d",
          ],
          addTags: ["svg", "circle", "line", "polyline", "path"],
        },
      }).then((form) => {
        // suppress the Form.io SDK default submit behavior so that we can submit
        // to our own backend with all necessary CSRF protection
        form.nosubmit = true;
        form.on("submit", (submission) => {
          formService
            .createOrUpdateSubmission(url, submission, submissionId)
            .then((submission) => {
              form.emit("submitDone", submission);
            });
        });
        form.on("submitDone", (submission) => {
          this.logSubmission(url, {}, submission.data);
        });
      });
    },
    async validateApplication() {
      if (!this.studentApplications.length) {
        await this.$store.dispatch("getFormioSubmissions");
        await this.$store.dispatch("getStudentApplications");
      }
      return this.studentApplications.some(
        (app) => app.id === this.enrollmentApplicationId
      );
    },
  },
};
</script>
