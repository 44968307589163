<template>
  <div
    id="general-container"
    :class="{ 'overflow-y-auto h-56': useScroll }"
    class="bg-gray-50 py-2 px-4 rounded-md border-dashed border-2"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "GeneralContainer",
  props: {
    useScroll: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
/*(CHROME, EDGE AND SAFARI) */

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgba(248, 248, 248, 0.5);
}

*::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 20px;
}

/* (FIREFOX) */
* {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1;
}
</style>
