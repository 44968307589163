<template>
  <FormPanel
    v-if="rankingOptions.length >= 3"
    :id="'rankingAccommodationPreference'"
    :title="'Ranking Your Accommodation Preference'"
    :disabled-panel="disabledPanel"
    :initially-expanded="!disabledPanel"
    class="mt-6"
    panel-content-class="text-gray-600 border-gray-200"
  >
    <template #content>
      <p class="mb-6">
        In case your first choice of accommodations is not available, please
        rank the remaining options in order of preference:
      </p>
      <div class="grid grid-cols-1 gap-y-4">
        <div
          class="flex text-xs font-semibold tracking-widest text-gray-500 uppercase"
        >
          <div class="w-16 mr-6">Rank</div>
          <div>Area of Importance</div>
        </div>
        <label
          v-for="option in rankingOptions"
          :key="'select' + option.value"
          class="flex items-center"
          :for="'select' + option.value"
        >
          <div class="w-16 mr-6">
            <select
              :id="'select' + option.value"
              v-model="v$.value[option.value].$model"
              class="form-select w-full"
            >
              <option
                v-for="n in rankingOptions.length"
                :key="'option' + n"
                :value="'option' + n"
              >
                {{ n }}
              </option>
            </select>
          </div>
          <div class="text-sm md:text-base">
            {{ option.label }}
          </div>
        </label>
        <div
          v-if="v$.value.validateDuplicates.$invalid"
          class="error text-error-900 text-sm"
        >
          Rank can not be duplicate
        </div>
      </div>
    </template>
  </FormPanel>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import housingForm from "../../../mixins/housingForm.js";

const validateDuplicates = (value, vm) => {
  let values = Object.values(vm.value).filter((item) => item);
  let isDuplicate = values.some((item, index) => {
    return values.indexOf(item) != index;
  });
  return !isDuplicate;
};

export default {
  name: "RankingAccommodation",
  mixins: [housingForm],
  props: {
    ranking: {
      type: Object,
      default: function () {
        return new Object();
      },
    },
    rankingOptions: {
      type: Array,
      default: function () {
        return new Array();
      },
    },
    preferenceOption: {
      type: String,
      default: "",
    },
  },
  emits: ["update:ranking"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        universityHousing: "",
        apartment: "",
        hostFamily: "",
        residencia: "",
        hotel: "",
        residenceHall: "",
      },
    };
  },
  validations: {
    value: {
      universityHousing: {},
      apartment: {},
      hostFamily: {},
      residencia: {},
      hotel: {},
      residenceHall: {},
      validateDuplicates,
    },
  },
  watch: {
    preferenceOption: {
      handler: function (val) {
        if (Object.hasOwnProperty.call(this.value, val)) {
          this.value[val] = "option1";

          if (this.rankingOptions.length == 2) {
            let filterOptions = this.rankingOptions.filter(
              (item) => item.value !== val
            );

            if (filterOptions.length)
              this.value[filterOptions[0].value] = "option2";
          }
        }
      },
      immediate: true,
    },
    value: {
      handler: function () {
        if (this.successSubmission) this.$emit("changeSuccessSubmission");
        this.$emit("update:ranking", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
