<template>
  <div id="forms-and-status">
    <h2 class="sm:px-0 hidden">Forms &amp; Status</h2>

    <div v-if="!loading" class="table-container sm:border sm:rounded">
      <table>
        <thead>
          <tr>
            <th scope="col">
              Form Name
            </th>
            <th scope="col">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          <singleStatus
            v-for="(value, name, index) in forms"
            :key="'stat' + index"
            :name="name"
            :status="value"
            :simplified-approval="simplifiedApproval"
          />

          <tr v-if="!hasNoForms">
            <td>Student has no submitted forms in this phase.</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import singleStatus from "./misc/form-status-single.vue";
export default {
  name: "FormsAndStatuses",
  components: {
    singleStatus: singleStatus,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    forms: {
      type: Object,
      default: function () {
        return {};
      },
    },
    simplifiedApproval: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasNoForms() {
      return this.forms && Object.keys(this.forms).length > 0;
    },
  },
};
</script>

<style scoped>
h2 {
  @apply px-4;
  @apply text-xl font-semibold;
}
.table-container {
  @apply px-3 mt-3;
  @apply bg-white border-t border-b border-gray-300;
}
table {
  @apply w-full;
}
thead th {
  @apply py-3;
  @apply text-xs tracking-widest text-left text-gray-500 uppercase;
}
</style>
