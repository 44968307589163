<template>
  <FormPanel
    id="academicProfilePanel"
    title="Academic Profile"
    panel-content-class="text-gray-600 border-gray-200"
  >
    <template #content>
      <AcademicProfile
        :application-id="applicationId"
        :application-step-form="false"
        :application-submissions="applicationSubmissions"
      />
    </template>
  </FormPanel>
</template>

<script>
import AcademicProfile from "@/components/forms/applicationSteps/AcademicProfile.vue";
import FormPanel from "@/components/forms/SharedComponents/panel.vue";

export default {
  name: "AcademicProfileForm",
  components: {
    AcademicProfile,
    FormPanel,
  },
  props: {
    applicationId: {
      type: String,
      default: "",
    },
    applicationSubmissions: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
