<template>
  <div
    v-if="showHealthAndWellnessTile"
    class="flex flex-col"
    data-cy="HealthWellnessCard"
  >
    <router-link
      :to="{
        name: 'enrollment/health-wellness',
        params: { applicationId: applicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
    >
      <CheckMarkIcon :completed="completed" />
      <p
        class="text-sm font-semibold ml-4 ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Health &amp; Wellness
      </p>
    </router-link>
  </div>
</template>

<script>
import CheckMarkIcon from "../forms/SharedComponents/CheckMarkIcon.vue";
import formIoApi from "../../mixins/formIoApi";
import postFormRules from "../../mixins/postFormRules";
import { eventBus } from "../../app";
import { mapState } from "vuex";

export default {
  name: "HealthWellness",
  components: { CheckMarkIcon },
  mixins: [formIoApi, postFormRules],
  props: {
    applicationId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formPath: "healthwellnessform",
      message: "",
    };
  },
  computed: {
    ...mapState(["currentApplicationSubmissions"]),
    completed() {
      return Object.hasOwn(this.currentApplicationSubmissions, this.formPath);
    },
  },
  async mounted() {
    if (this.showHealthAndWellnessTile) {
      eventBus.$emit("addPreDepartureForm", {
        label: "Health & Wellness",
        ruleName: "Health And Wellness",
        routeName: "enrollment/health-wellness",
        submitted: this.completed,
      });
    }
  },
};
</script>

<style></style>
