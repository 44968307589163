<template>
  <div v-if="showHSCodeOfConductTile" class="flex flex-col">
    <router-link
      :to="{
        name: 'hs-code-of-conduct',
        params: { applicationId: applicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
    >
      <CheckMarkIcon :completed="completed" />
      <p
        class="text-sm font-semibold ml-4 ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Code of Conduct High School Programs
      </p>
    </router-link>
  </div>
</template>

<script>
import CheckMarkIcon from "@/components/forms/SharedComponents/CheckMarkIcon.vue";
import formIoApi from "@/mixins/formIoApi.js";
import postFormRules from "@/mixins/postFormRules.js";
import { eventBus } from "@/app";
import { mapState } from "vuex";

export default {
  name: "HSCodeOfConductCard",
  components: { CheckMarkIcon },
  mixins: [formIoApi, postFormRules],
  data() {
    return {
      formURL: "hscodeofconduct",
    };
  },
  computed: {
    ...mapState(["currentApplicationSubmissions"]),
    completed() {
      return Object.hasOwn(this.currentApplicationSubmissions, this.formURL);
    },
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  async mounted() {
    if (this.showHSCodeOfConductTile) {
      eventBus.$emit("addPreDepartureForm", {
        label: "Code of Conduct High School Programs",
        ruleName: "HS Code of Conduct",
        routeName: "hs-code-of-conduct",
        submitted: this.completed,
      });
    }
  },
};
</script>
