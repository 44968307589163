<template>
  <div v-if="showPassportTile" class="flex flex-col">
    <router-link
      :to="{
        name: 'passport',
        params: { applicationId: applicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
    >
      <CheckMarkIcon :completed="completed" />
      <p
        class="text-sm font-semibold ml-4 ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Passport Information
      </p>
    </router-link>
  </div>
</template>

<script>
import CheckMarkIcon from "../SharedComponents/CheckMarkIcon.vue";
import formIoApi from "../../../mixins/formIoApi.js";
import postFormRules from "../../../mixins/postFormRules.js";
import { eventBus } from "../../../app";
import { mapState } from "vuex";

export default {
  name: "PassportsCard",
  components: { CheckMarkIcon },
  mixins: [formIoApi, postFormRules],
  data() {
    return {
      formURL: "passport",
    };
  },
  computed: {
    ...mapState(["currentApplicationSubmissions"]),
    completed() {
      return Object.hasOwn(this.currentApplicationSubmissions, this.formURL);
    },
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  async mounted() {
    if (this.showPassportTile) {
      eventBus.$emit("addPreDepartureForm", {
        label: "Passport Information",
        ruleName: "Passport Info",
        routeName: "passport",
        submitted: this.completed,
      });
    }
  },
};
</script>
