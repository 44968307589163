import formIoApi from "@/mixins/formIoApi.js";

export default {
  mixins: [formIoApi],
  data() {
    return {
      gridItems: [],
      editViewItems: [],
      idIndex: 1,
    };
  },
  computed: {
    applicationId() {
      return this.$route.params.applicationId;
    },
    detailViewItems() {
      return this.gridItems.filter((item) => !item.editView);
    },
  },
  methods: {
    getIndex(items, id) {
      return items.findIndex((item) => item.id === id);
    },
    deleteEditItem(id) {
      let index = this.getIndex(this.editViewItems, id);
      if (index !== -1) this.editViewItems.splice(index, 1);
    },
    //Edit view actions
    async saveItem(newItem) {
      //Update item on editViewItems
      let editIndex = this.getIndex(this.editViewItems, newItem.id);
      this.editViewItems[editIndex] = JSON.parse(JSON.stringify(newItem));

      //Create new array to submit form, if the item already exist we replace it if not we add a new one
      let newArray = JSON.parse(JSON.stringify(this.gridItems));
      let itemIndex = newArray.findIndex((item) => item.id === newItem.id);

      if (itemIndex !== -1) newArray[itemIndex] = newItem;
      else newArray.push(newItem);

      let response = await this.saveForm(newArray);

      // If the response is success we will remove the item from editViewItems and update gridItems
      // If not we're goin to send the error to editViewItems
      editIndex = this.getIndex(this.editViewItems, newItem.id);
      this.editViewItems[editIndex].submitError = response.error ?? "";

      if (response.success) {
        let index = this.getIndex(this.gridItems, newItem.id);

        if (index !== -1) {
          this.gridItems[index] = JSON.parse(JSON.stringify(newItem));
        } else {
          this.gridItems.push(newItem);
          index = this.getIndex(this.gridItems, newItem.id);
        }

        this.gridItems[index].editView = false;
        this.deleteEditItem(newItem.id);
      }
    },
    async saveForm(items) {
      const jsonData = this.getJsonData(items);
      const method = this.submissionId ? "PUT" : "POST";
      let result = {
        success: false,
        error: "",
      };

      return this.submitToFormIo(
        this.formURL,
        jsonData,
        method,
        this.submissionId
      )
        .then((response) => {
          this.submissionId = response;
          result.success = true;
          return result;
        })
        .catch((error) => {
          result.error = error;
          return result;
        });
    },
    cancelEditView(id) {
      const index = this.getIndex(this.gridItems, id);
      this.deleteEditItem(id);

      if (index !== -1) this.gridItems[index].editView = false;
    },
    //Detail view actions
    editItem(id) {
      let index = this.getIndex(this.gridItems, id);
      this.gridItems[index].editView = true;

      this.editViewItems.push(
        JSON.parse(JSON.stringify(this.gridItems[index]))
      );
    },
    async deleteItem(id) {
      //Create new array to submit form without the item
      let newArray = JSON.parse(JSON.stringify(this.gridItems));
      let index = newArray.findIndex((item) => item.id === id);
      newArray.splice(index, 1);

      //If response is success remove item from gridItems
      let response = await this.saveForm(newArray);
      if (response.success) {
        let gridIndex = this.getIndex(this.gridItems, id);
        if (gridIndex !== -1) this.gridItems.splice(gridIndex, 1);
      }
    },
  },
};
