<template>
  <div>
    <label v-if="label" class="block text-sm font-medium text-gray-700">{{
      label
    }}</label>
    <div class="relative mt-1 rounded-md shadow-sm">
      <div
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
      >
        <span class="text-gray-700 sm:text-sm">$</span>
      </div>
      <input
        :id="inputId"
        v-model="value"
        type="text"
        :name="generateId"
        class="block w-full rounded-md border-gray-800 pl-7 pr-12 focus:border-blue-500 focus:ring-blue-500 sm:text-sm text-gray-700"
        :class="[customClasses, { 'bg-error-100': validationErrors.$error }]"
        :maxlength="maxLength"
        :aria-labelledby="ariaLabelledBy"
        @input="formatCurrency"
      />
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <span id="currency" class="text-gray-700 sm:text-sm">USD</span>
      </div>
    </div>
  </div>
</template>

<script>
import validationMessages from "../../../mixins/validationMessages";
export default {
  mixins: [validationMessages],
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    maxLength: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    ariaLabelledBy: {
      type: String,
      default: "",
    },
    customClasses: {
      type: String,
      default: "",
    },
  },
  emits: ["input"],
  data() {
    return {
      value: "",
      inputName: "",
      inputId: "",
      resetting: false,
    };
  },
  computed: {
    generateId() {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 10; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },
  watch: {
    value: function (newVal) {
      if (!this.resetting) {
        this.$emit("input", newVal);
      } else {
        this.resetting = false;
      }
    },
  },
  created() {
    this.inputName = this.name ?? this.generateId;
    this.inputId = this.id ?? this.generateId;
  },
  methods: {
    reset() {
      this.resetting = true;
      this.value = "";
    },
    formatCurrency(el) {
      let newAmount = el.target.value.toString().replace(/\./g, "").trim();
      if (!newAmount) {
        this.value = "";
        return;
      }

      if (isNaN(newAmount) || newAmount == 0) {
        return;
      }

      newAmount = parseFloat(newAmount.replace(/[^\d]/g, "")) / 100;
      this.value = newAmount.toFixed(2);
    },
  },
};
</script>
