<template>
  <InstitutionPays
    v-if="showInstitutionForm"
    :show-instructions="applicationStatus !== 'Accepted'"
  />
  <PayDepositForm v-else />
</template>

<script>
import { mapState } from "vuex";
import InstitutionPays from "@/components/forms/PayDeposit/InstitutionPays";
import PayDepositForm from "@/components/forms/PayDeposit/PayDepositForm";

export default {
  name: "AddressesForm",
  components: {
    InstitutionPays,
    PayDepositForm,
  },
  props: {
    currentApplicationData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loadingStatus: true,
      applicationStatus: "",
    };
  },
  computed: {
    ...mapState(["studentApplications", "institutionPaysApplicationFee"]),
    showInstitutionForm() {
      return (
        this.applicationStatus === "Accepted" ||
        this.institutionPaysApplicationFee
      );
    },
  },
  watch: {
    currentApplicationData: {
      handler: function (val) {
        this.setApplicationStatus(val);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    setApplicationStatus(data) {
      if (Object.keys(data).length > 0)
        this.applicationStatus = data.bulkEnrollmentStatus ?? "";
    },
  },
};
</script>
