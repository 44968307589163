export default {
  props: {
    selectedOption: {
      type: String,
      default: "",
    },
    typedText: {
      type: String,
      default: "",
    },
  },
  emits: ["input"],
  methods: {
    //set the correspondign field value and emit event.
    //rootLevelProperty refers whether the variable we are changing is a direct child of data property.
    onFieldChange(field, value, rootLevelProperty = false) {
      if (rootLevelProperty) {
        this[field] = value;
        this.$emit("input", this[field]);
      } else {
        this.value[field] = value;
        this.$emit("input", this.value);
      }
    },
  },
};
