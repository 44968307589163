<template>
  <div>
    <!--Form-->
    <template v-if="!loading">
      <PageLayout>
        <template #pageHeader>
          <BackLink :an-href="url" />
        </template>
        <template #pageContent>
          <div id="cpcode-form" class="mt-10 relative" role="form" />
        </template>
      </PageLayout>
    </template>

    <!-- Spiner -->
    <template v-else>
      <CountDown
        ref="counter"
        :message="message"
        @redirecting="(msg) => (message = msg)"
      />
    </template>
  </div>
</template>

<script>
import { Formio } from "formiojs";
import tailwind from "@apiabroad/formio-tailwind-template";
import PageLayout from "@/components/forms/SharedComponents/Layout/PageDefault.vue";
import BackLink from "@/components/forms/SharedComponents/Link.vue";
import CountDown from "@/components/forms/SharedComponents/CountDown.vue";
import forms from "@/mixins/forms";
import formioApiMix from "@/mixins/formIoApi";
import impersonationFormSniffer from "@/mixins/ImpersonationFormSniffer";
import formService from "../../services/form";

export default {
  name: "CustomizedCodeOfConduct",
  components: {
    PageLayout,
    BackLink,
    CountDown,
  },
  mixins: [forms, formioApiMix, impersonationFormSniffer],
  data() {
    return {
      loading: true,
      message: "",
      formPath: "cpcodeofconduct",
    };
  },
  computed: {
    applicationId() {
      return this.$route.params.applicationId;
    },
    url() {
      return this.$router.resolve({
        name: "applications/landing",
        params: { applicationId: this.applicationId },
      }).href;
    },
    formUrl() {
      let url = `/api/forms/${this.formPath}`;
      if (this.submission_id) url += "/submission/" + this.submission_id;
      return url;
    },
  },
  watch: {
    loading: {
      handler: function (newval) {
        if (!newval) {
          this.message = "Rendering form";
          setTimeout(() => {
            this.loadForm();
          }, 1000);
        }
      },
    },
  },
  async created() {
    // Check if form rule is on "form_rules" or "post_acceptance_form_rules"
    let canView = await this.canView();
    if (!canView) {
      this.$refs.counter.startCountDown();
    } else {
      // Try to find form submission
      let data = await this.checkSubmission();
      if (data) {
        this.submission_id = data;
      }
      this.loading = false;
    }
  },
  methods: {
    async canView() {
      this.message = "Validating form, please wait.";
      const valid = await this.isValidApplicationAndIsOnRules(
        this.applicationId,
        "CP Code of Conduct- Study"
      );
      return valid.valid;
    },
    checkSubmission() {
      this.message = "Getting form submission, please wait";
      return this.formioSubmissionExists(this.formPath, this.applicationId);
    },
    loadForm() {
      let element = document.getElementById("cpcode-form");
      Formio.setBaseUrl(`${window.location.protocol}//${window.location.host}`);
      Formio.setAuthUrl(
        `${window.location.protocol}//${window.location.host}/api/forms`
      );
      Formio.use(tailwind);
      Formio.createForm(element, this.formUrl, {
        hooks: {
          beforeSubmit: (submission, next) => {
            submission.data.application_id = this.applicationId;
            submission.data.completedForm = true;
            submission = this.attachEmployeeToSubmission(submission, "post");
            next();
          },
        },
        noAlerts: true,
      }).then((form) => {
        form.nosubmit = true;
        form.on("submit", (submission) => {
          formService
            .createOrUpdateSubmission(this.formPath, submission, this.submission_id)
            .then((submission) => {
              form.emit("submitDone", submission);
            });
        });
        form.on("submitDone", (submission) => {
          this.logSubmission(this.formPath, {}, submission.data);
          this.submission_id = submission._id;
          this.$router.push({
            name: "applications/landing",
            params: {
              applicationId: this.applicationId,
            },
          });
        });
      });
    },
  },
};
</script>
