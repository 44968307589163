<template>
  <div>
    <template v-if="loading">
      <countdown
        ref="counter"
        :message="message"
        @redirecting="(msg) => (message = msg)"
      />
    </template>
    <template v-else>
      <PageStyle>
        <template #pageHeader>
          <router-link
            :to="{
              name: 'applications/landing',
              params: { applicationId: applicationId },
            }"
            class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-left w-4 h-4"
            >
              <polyline points="15 18 9 12 15 6" />
            </svg>
            <span class="ml-1">Back</span>
          </router-link>
        </template>
        <template #pageContent>
          <FormPanel
            :id="'headshotPanel'"
            :title="'Headshot'"
            class="mt-6"
            panel-content-class="text-gray-600 border-gray-200"
          >
            <template #content>
              <HeadShot
                :application-id="applicationId"
                :submission-id="submissionId"
                :submission-data="submissionData"
                :component-form="formURL"
                @set-form-info="formUpdate"
              />
            </template>
          </FormPanel>
        </template>
      </PageStyle>
    </template>
  </div>
</template>

<script>
import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import PageStyle from "@/components/forms/SharedComponents/Layout/PageDefault.vue";
import HeadShot from "@/components/forms/HeadShot/Headshot.vue";
import forms from "@/mixins/forms";
import countdown from "@/components/forms/SharedComponents/CountDown.vue";
import formIoApi from "../../../mixins/formIoApi.js";

export default {
  name: "HeadshotForm",
  components: {
    FormPanel,
    PageStyle,
    HeadShot,
    countdown,
  },
  mixins: [formIoApi, forms],
  data() {
    return {
      submissionId: "",
      formURL: "headshot",
      loading: true,
      message: "",
    };
  },
  computed: {
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  async created() {
    let can = await this.canView();
    if (can) {
      let dataId = await this.getLatestId();
      if (dataId) {
        this.submissionId = dataId;
        let data = await this.getFormioData();
        if (data) {
          this.submissionData = data;
        }
      }
      this.loading = false;
      return;
    }
    this.$refs.counter.startCountDown();
  },
  methods: {
    async canView() {
      this.message = "Checking if form is aplicable to your program";
      const valid = await this.isValidApplicationAndIsOnRules(
        this.applicationId,
        "Head Shot"
      );

      return valid.valid;
    },
    formUpdate(formInformation) {
      this.submissionId = formInformation.id;
      this.submissionData = formInformation.data;
    },
    getLatestId() {
      this.message = "Checking for previous submissions";
      return this.formioSubmissionExists(this.formURL, this.applicationId);
    },
    getFormioData() {
      this.message = "Getting form information";
      return this.getFormioSubmission(this.formURL, this.submissionId);
    },
  },
};
</script>
