<template>
  <div class="flex flex-wrap justify-center items-center px-3 my-2">
    <span class="mr-4 font-semibold"> Adding </span>
    <div
      :class="{ 'bg-gray-200': !selected }"
      class="flex items-center flex-wrap rounded-xl px-1 py-1 justify-evenly"
    >
      <span
        v-if="!selected || selected !== 'accepted'"
        class="rounded-xl px-8 py-1 cursor-pointer"
        :class="{
          'bg-info-900 text-white': !currentStatus,
          'text-gray-600': currentStatus,
        }"
        @click="handleToggleClick"
        >Applicants</span
      >
      <span
        v-if="!selected || selected !== 'application started'"
        class="rounded-xl px-8 py-1 cursor-pointer"
        :class="{
          'bg-info-900 text-white': currentStatus,
          'text-gray-600': !currentStatus,
        }"
        @click="handleToggleClick"
        >Accepted</span
      >
    </div>
    <div v-if="currentStep === 0" class="mb-2 mt-5">
      <p class="mb-2 text-gray-800">
        <strong>{{ currentStatus ? "Accepted" : "Applicant" }}:</strong>
        {{ statusDescription }}
      </p>
      <hr />
    </div>
  </div>
</template>

<script>
/* Expects the current status and returns the new status as an $emit event
 that can be catched by @toggle-status-accepted */
export default {
  name: "ToggleStatusAccepted",
  props: {
    accepted: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: String,
      default: "",
    },
    currentStep: {
      type: Number,
      default: 0,
    },
  },
  emits: ["toggle-status-accepted"],
  data() {
    return {
      currentStatus: this.accepted ? this.accepted : false,
      disableAccepted: this.selected ? this.accepted : false,
    };
  },
  computed: {
    statusDescription() {
      return this.currentStatus
        ? "Student has been reviewed by the university faculty leading a custom or adopted program and is approved to go on the program"
        : "Student knows which program they plan to participate in. This creates an APIConnect account for the students and allows them to continue their application process.";
    },
  },
  mounted() {
    if (this.selected) {
      this.currentStatus = this.selected === "accepted" ? true : false;
    }
    this.$emit("toggle-status-accepted", this.currentStatus);
  },
  methods: {
    handleToggleClick() {
      if (!this.selected) {
        this.$emit(
          "toggle-status-accepted",
          (this.currentStatus = !this.currentStatus)
        );
      }
    },
  },
};
</script>
