<template>
  <PageStyle>
    <template #pageHeader>
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </template>
    <template #pageContent>
      <h1 class="mb-2 text-xl md:font-semibold md:text-2xl">
        Custom Participant Agreement
      </h1>
      <div
        id="cp-participant-agreement"
        role="form"
        aria-label="Participant Agreement Form"
        class="mt-10 relative"
      />
    </template>
  </PageStyle>
</template>
<script>
import PageStyle from "@/components/forms/SharedComponents/Layout/PageDefault.vue";
import { Formio } from "formiojs";
import tailwind from "@apiabroad/formio-tailwind-template";
import formIoApi from "@/mixins/formIoApi.js";
import forms from "@/mixins/forms.js";
import impersonationFormSniffer from "@/mixins/ImpersonationFormSniffer";
import formService from "../../../services/form";

export default {
  name: "NoInsuranceDirectPayForm",
  components: {
    PageStyle,
  },
  mixins: [formIoApi, forms, impersonationFormSniffer],
  data() {
    return {
      submissionId: "",
      formURL: "cpparticipantagreementnoinsurancedirectpay",
    };
  },
  computed: {
    applicationId() {
      return this.$route?.params?.applicationId;
    },
  },
  async created() {
    const valid = await this.isValidApplication(
      this.applicationId,
      "CP Participant Agreement Form - No Insurance, Direct Pay",
      "post_acceptance_form_rules"
    );

    if (valid) {
      this.getFormioData();
    } else {
      window.location.href = "/applications";
    }
  },
  methods: {
    getFormioData() {
      this.formioSubmissionExists(this.formURL, this.applicationId).then(
        (submissionId) => {
          this.submissionId = submissionId;
          this.createPDF();
        }
      );
    },
    createPDF() {
      let element = document.getElementById("cp-participant-agreement");
      let url = `/api/forms/${this.formURL}`;
      if (this.submissionId) {
        url += `/submission/${this.submissionId}`;
      }
      Formio.setBaseUrl(`${window.location.protocol}//${window.location.host}`);
      Formio.setAuthUrl(
        `${window.location.protocol}//${window.location.host}/api/forms`
      );
      Formio.use(tailwind);
      Formio.createForm(element, url, {
        hooks: {
          beforeSubmit: (submission, next) => {
            submission.data.application_id = this.applicationId;
            submission = this.attachEmployeeToSubmission(submission, "post");
            next();
          },
        },
        noAlerts: true,
      }).then((form) => {
        // suppress the Form.io SDK default submit behavior so that we can submit
        // to our own backend with all necessary CSRF protection
        form.nosubmit = true;
        form.on("submit", (submission) => {
          formService
            .createOrUpdateSubmission(
              this.formURL,
              submission,
              this.submissionId
            )
            .then((submission) => form.emit("submitDone", submission))
        });
        form.on("submitDone", (submission) => {
          this.logSubmission(this.formURL, {}, submission.data);
          this.$router.push({
            name: "applications/landing",
            params: {
              applicationId: this.applicationId,
            },
          });
        });
      });
    },
  },
};
</script>
