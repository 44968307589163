<template>
  <div class="w-full px-4 pb-4 mx-auto max-w-7xl sm:px-8 sm:pb-8">
    <div class="my-4">
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: enrollmentApplicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </div>
    <div
      class="px-4 py-6 -mx-4 bg-white border-t border-gray-200 sm:-mx-8 md:mx-auto sm:px-8 md:p-12 md:border md:rounded"
    >
      <h1 class="mb-2 text-xl md:font-semibold md:text-2xl">
        Parsons Paris Use of Your Image and Your Work
      </h1>
      <div class="mt-10 relative" id="formio-parsons" />
    </div>
  </div>
</template>

<script>
import enrollmentForms from "../../mixins/enrollmentForms.js";
import formIoApi from "../../mixins/formIoApi";

export default {
  name: "ParsonsParisAgreement",
  mixins: [enrollmentForms, formIoApi],
  data() {
    return {
      formURL: "parsonsparisagreement",
    };
  },
  watch: {
    studentApplications: {
      handler: function () {
        this.setPageData();
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch("prepareFormio").then(() => {
      this.setPageData();
    });
  },
  methods: {
    setPageData() {
      if (this.validateApplication()) {
        this.formioSubmissionExists(
          this.formURL,
          this.enrollmentApplicationId
        ).then((submissionId) => {
          this.createForm(
            `/api/forms/${this.formURL}`,
            "formio-parsons",
            submissionId,
            this.formURL
          );
        });
      }
    },
  },
};
</script>
