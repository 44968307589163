<template>
  <div class="w-full mx-auto max-w-7xl md:px-8 md:mb-8 mt-8">
    <div class="bg-white sm:grid sm:grid-cols-12 sm:gap-6 md:gap-12">
      <div id="formioformid" class="col-span-12"></div>
      <div v-if="formIsEmpty">
        <h1>We have problems to show you the selected form, try again later</h1>
      </div>
      <!--Prevent purgecss from removing classes-->
      <div :class="classes" style="display: none;"></div>
    </div>
  </div>
</template>

<script>
import { Formio } from "formiojs";
import FormioUtils from "formiojs/utils";
import tailwind from "@apiabroad/formio-tailwind-template";
import { mapMutations, mapGetters, mapState } from "vuex";
const hardCodedClasses =
  "hidden grid row-gap-4 col-gap-4 p-5 transition-colors duration-150 ease-linear border rounded grid-cols-60-1fr md:col-gap-8 md:row-gap-4 md:grid-cols-80-1fr flex col-span-1 row-span-1 md:items-center md:row-span-2 items-center col-span-2 text-sm text-gray-600 md:col-span-1 md:text-base px-4 pb-6 mt-6 -mx-4 sm:mx-0 sm:px-0 lg:grid lg:grid-cols-1 lg:row-gap-3 lg:pb-0 lg:mt-0 grid-cols-7 row-gap-6 col-gap-6 lg:col-span-1 lg:flex-row lg:col-span-2 lg:col-span-3 w-32 max-w-6xl h-auto mx-auto formio-iframe";

export default {
  name: "FormioFormRenderer",
  props: {
    minimumGpa: {
      type: Number,
      default: 0,
    },
    statementOfPurpose: {
      type: String,
      default: "",
    },
    formrules: {
      type: Array,
      default: function () {
        return [];
      },
    },
    postacceptancerules: {
      type: Array,
      default: function () {
        return [];
      },
    },
    housingrules: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      classes: hardCodedClasses, // Temporal solution for whitelist & whitelist patterns not working on post-css config
      form: {},
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    ...mapGetters("university", ["formDesignSelected"]),
    formIsEmpty() {
      return (
        Object.keys(this.formDesignSelected).length === 0 &&
        this.formDesignSelected.constructor === Object
      );
    },
  },
  watch: {
    formIsEmpty: {
      handler: function (newval) {
        if (!newval) {
          this.renderForm();
        }
      },
    },
  },
  mounted() {
    this.renderForm();
  },
  beforeUnmount() {
    this.updateFormDesignModalOpen(false);
    this.updateFormDesignSelected({});
  },
  methods: {
    ...mapMutations("university", [
      "updateFormDesignModalOpen",
      "updateFormDesignSelected",
    ]),
    trailingSlash(url) {
      return url.replace(/\/?$/, "/");
    },
    revertCamelCase(str) {
      // adding space between strings
      const result = str.replace(/([A-Z])/g, " $1");

      // converting first character to uppercase and join it to the final string
      const final = result.charAt(0).toUpperCase() + result.slice(1);

      return final;
    },
    renderForm() {
      const universityName = this.currentUser?.university?.name;
      const formElement = document.getElementById("formioformid");
      Formio.use(tailwind);
      Formio.setBaseUrl(
        `${window.location.protocol}//${window.location.hostname}`
      );
      Formio.setAuthUrl(
        `${window.location.protocol}//${window.location.host}/api/forms`
      );
      this.form = Formio.createForm(
        formElement,
        `/api/forms/${this.formDesignSelected.handle}`,
        {
          sanitizeConfig: {
            addAttr: this.sanitizeAttributes,
            addTags: this.tagsAttributes,
          },
          hooks: {
            beforeSubmit: (submission, next) => {
              window.scrollTo(0, 0);
              next([{ message: "Advisors can't submit student forms" }]);
            },
          },
        }
      ).then((formioform) => {
        formioform.on("formLoad", () => {
          this.setFormData(formioform);
          let byFormRules = this.getComponentsToRemove(formioform);
          let bySections = this.getComponentsToRemove(formioform, "section");
          let allComponents = this.keepIfNeeded(byFormRules.concat(bySections));
          this.removeComponents(formioform, allComponents);
          this.removeSelectedComponents(formioform);
          this.processHousingRules(formioform);

          if (this.minimumGpa && universityName) {
            let tempStatement = formioform.getComponent("GpaStatementText");
            let currentStatementText = tempStatement.content;
            tempStatement.component.content = currentStatementText
              .replace("[gpa]", this.minimumGpa)
              .replace("[universityName]", universityName);
            formioform.redraw();
          }
        });
      });
    },
    getComponentsToRemove(form, processType = "formrules") {
      let componentsToRemove = [];
      switch (processType) {
        case "formrules":
          if (this.formrules && this.formrules.length) {
            FormioUtils.eachComponent(
              form.components,
              (component) => {
                const componentRuleName =
                  component.component.properties["data-label"];
                if (componentRuleName) {
                  if (!this.formrules.includes(componentRuleName)) {
                    componentsToRemove.push(component);
                  }
                }
              },
              true
            );
          }
          break;
        case "section":
          if (
            this.formDesignSelected.showSections &&
            this.formDesignSelected.showSections.length
          ) {
            FormioUtils.eachComponent(
              form.components,
              (component) => {
                if (
                  !this.formDesignSelected.showSections.includes(
                    component.component.key
                  )
                ) {
                  componentsToRemove.push(component);
                }
              },
              true
            );
          }
          break;
      }
      return componentsToRemove;
    },
    setFormData(form) {
      let i = 0;
      let paramsLength = this.formDesignSelected.extraParams.length;
      // There are two ways of setting data. 1: Assign a value to a component directly, 2: Assign value through a custom method
      // For option #2, the method must exists inside this component, if not nothing will change.
      for (i; i < paramsLength; i++) {
        if (
          Object.hasOwnProperty.call(
            this.formDesignSelected.extraParams[i],
            "by"
          ) &&
          this.formDesignSelected.extraParams[i]["by"]
        ) {
          let by = this.formDesignSelected.extraParams[i]["by"];
          switch (by) {
            case "assignValue": // Assign value directly from the config file
              if (
                Object.hasOwnProperty.call(
                  this.formDesignSelected.extraParams[i],
                  "formioComponentName"
                ) &&
                Object.hasOwnProperty.call(
                  this.formDesignSelected.extraParams[i],
                  "value"
                ) &&
                this.formDesignSelected.extraParams[i]["formioComponentName"] &&
                this.formDesignSelected.extraParams[i]["value"]
              ) {
                form.data[
                  this.formDesignSelected.extraParams[i]["formioComponentName"]
                ] = this.formDesignSelected.extraParams[i]["value"];
              }
              break;
            case "method": // Will Execute a method to assign the value
              if (
                Object.hasOwnProperty.call(
                  this.formDesignSelected.extraParams[i],
                  "method"
                ) &&
                this.formDesignSelected.extraParams[i]["method"] &&
                typeof this[
                  this.formDesignSelected.extraParams[i]["method"]
                ] === "function"
              ) {
                this[this.formDesignSelected.extraParams[i]["method"]](form);
              }
              break;
          }
        }
      }
      form.redraw();
    },
    removeComponents(form, components) {
      let i = 0;
      let componentsLength = components.length;
      for (i; i < componentsLength; i++) {
        form.removeComponent(components[i]);
      }
      form.redraw();
    },
    keepIfNeeded(list) {
      // Removes from list of components to be removed if its needed by a configured section
      let items = [];
      if (this.formDesignSelected.showSections.length) {
        items = list.filter(
          (item) =>
            !this.formDesignSelected.showSections.includes(
              item["component"]["key"]
            )
        );
      }
      return items;
    },
    removeSelectedComponents(form) {
      if (
        Object.hasOwnProperty.call(this.formDesignSelected, "removeSections")
      ) {
        let i = 0;
        for (i; i < this.formDesignSelected.removeSections.length; i++) {
          let component = form.getComponent(
            this.formDesignSelected.removeSections[i]
          );
          if (component) {
            component.component.hidden = true;
            form.redraw();
          }
        }
      }
    },
    processHousingRules(form) {
      // Query to get component
      let component = FormioUtils.findComponents(form.components, {
        type: "radio",
        key: "accommodationStylePreference",
      });
      // Check that only one component has returned
      if (Array.isArray(component) && component.length === 1) {
        let newOptions = [];
        let i = 0;
        for (i = 0; i < component[0]["component"]["values"].length; i++) {
          // Checking that component has attribute 'value'
          let optionValue = Object.hasOwnProperty.call(
            component[0]["component"]["values"][i],
            "value"
          )
            ? this.revertCamelCase(
                component[0]["component"]["values"][i]["value"]
              )
            : "";
          // Adding component to new set of options if it's on the program housing rules list
          if (this.housingrules.includes(optionValue)) {
            newOptions.push(component[0]["component"]["values"][i]);
          }
        }
        component[0]["component"]["values"] = newOptions;
        component[0].redraw();
      }
    },
    setStatementOfPurpose(form) {
      let statement = form.getComponent("StatementofPurposeText");
      if (statement && this.statementOfPurpose) {
        statement.component.content = this.statementOfPurpose;
        statement.redraw();
      }
    },
  },
};
</script>
