<template>
  <div v-if="showTravelPlansTile" class="flex flex-col">
    <router-link
      :to="{
        name: 'enrollment/travel-plans',
        params: { applicationId: applicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
      data-cy="travel-plans-enrollment-link"
    >
      <CheckMarkIcon :completed="completed" />
      <p
        class="text-sm font-semibold ml-4 ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Travel Plans
      </p>
    </router-link>
  </div>
</template>

<script>
import CheckMarkIcon from "../../forms/SharedComponents/CheckMarkIcon.vue";
import formIoApi from "../../../mixins/formIoApi";
import postFormRules from "../../../mixins/postFormRules";
import { eventBus } from "../../../app";
import { POST_FORM_RULES } from "../../../constants";
import { mapState } from "vuex";

export default {
  name: "TravelPlansCard",
  components: { CheckMarkIcon },
  mixins: [formIoApi, postFormRules],
  props: {
    applicationId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formPath: "travelarrangements",
    };
  },
  computed: {
    ...mapState(["currentApplicationSubmissions"]),
    completed() {
      if (!Object.hasOwn(this.currentApplicationSubmissions, this.formPath)) {
        return false;
      }

      return this.currentApplicationSubmissions[this.formPath].some(
        (submission) => submission?.state === "submitted"
      );
    },
    buildQueryString() {
      return "data.application_id=" + this.applicationId + "&state=submitted";
    },
  },
  async mounted() {
    if (this.showTravelPlansTile) {
      eventBus.$emit("addPreDepartureForm", {
        label: "Travel Plans",
        ruleName: POST_FORM_RULES["Travel Plans"],
        routeName: "enrollment/travel-plans",
        submitted: this.completed,
        travelSection: true,
      });
    }
  },
};
</script>

<style></style>
