<template>
  <fieldset>
    <legend>
      Do you have any learning differences that have been documented with an
      academic institution you have attended and warrant special accommodation?
    </legend>
    <RadioButton
      name="LearningDifferencesRadio"
      aria-controls="learning-differences-radio"
      :options="['yes', 'no']"
      :selected-option="selectedOption"
      :validation-errors="v$.value.selectedRadio"
      @input="onFieldChange('selectedRadio', $event)"
    />

    <div
      v-if="value.selectedRadio === 'yes'"
      id="learning-differences-radio"
      :aria-expanded="value.selectedRadio === 'yes'"
    >
      <div class="grid grid-cols-1 gap-y-8 ml-6">
        <ApiTextArea
          name="learning-differences-textarea"
          label="If yes, provide details here, including any accommodations requested."
          :max-length="500"
          :typed-text="typedText"
          :validation-errors="v$.value.textarea"
          @textUpdate="onFieldChange('textarea', $event)"
        />
        <p class="italic">
          Note: If you have documented academic accommodations with your current
          academic institution, please email a copy of your approved
          accommodations letter to your Program Manager for review by your host
          institution. The letter should include as much detail as possible
          about the accommodations you receive. API cannot guarantee that host
          universities abroad will be able to offer all requested accommodations
          nor be ADA compliant.
        </p>
      </div>
    </div>
  </fieldset>
</template>

<script>
import formInputs from "../../mixins/formInputs";
import RadioButton from "../forms/SharedComponents/RadioButton";
import ApiTextArea from "../forms/SharedComponents/ApiTextArea";
import { required, requiredIf, maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  name: "LearningDifferences",
  components: { RadioButton, ApiTextArea },
  mixins: [formInputs],
  props: {
    learningDifferences: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:learning-differences"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        selectedRadio: "",
        textarea: "",
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:learning-differences", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {
    value: {
      selectedRadio: {
        required,
      },
      textarea: {
        required: requiredIf(function () {
          return this.value.selectedRadio === "yes";
        }),
        maxLength: maxLength(500),
      },
    },
  },
};
</script>
